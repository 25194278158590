import * as React from 'react';

function Advantage1(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16244" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="101">
                <rect y="0.5" width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16244)">
                <path
                    d="M80.2669 76.6647L91.0086 89.2971C92.2392 90.749 94.3875 90.9079 95.8163 89.6574C97.245 88.4069 97.4014 86.2238 96.1708 84.7719L85.4292 72.1396M51.5877 75.7322H44.3606C43.7453 75.7322 43.2447 75.2235 43.2447 74.5982V42.9643C43.2447 42.3391 43.7453 41.8304 44.3606 41.8304H54.7164M63.3723 36.7435V12.6339C63.3723 12.0087 63.8729 11.5 64.4882 11.5H74.844C75.4593 11.5 75.9598 12.0087 75.9598 12.6339V36.2666M89.1105 59.4224C89.6111 69.6385 81.873 78.3392 71.8092 78.8585C61.7558 79.3671 53.1938 71.5037 52.6828 61.277C52.1822 51.0609 59.9204 42.3603 69.9841 41.841C80.0375 41.3323 88.5995 49.1957 89.1105 59.4224ZM4.11588 56.3597H14.4717C15.088 56.3597 15.5876 56.8674 15.5876 57.4937V74.5982C15.5876 75.2245 15.088 75.7322 14.4717 75.7322H4.11588C3.4996 75.7322 3 75.2245 3 74.5982V57.4937C3 56.8674 3.4996 56.3597 4.11588 56.3597ZM24.2435 30.5015H34.5992C35.2155 30.5015 35.7151 31.0092 35.7151 31.6355V74.6088C35.7151 75.2351 35.2155 75.7428 34.5992 75.7428H24.2435C23.6272 75.7428 23.1276 75.2351 23.1276 74.6088V31.6355C23.1276 31.0092 23.6272 30.5015 24.2435 30.5015ZM80.9865 60.461C80.9865 66.3139 76.3174 71.0586 70.5577 71.0586C64.7981 71.0586 60.1289 66.3139 60.1289 60.461C60.1289 54.6081 64.7981 49.8634 70.5577 49.8634C76.3174 49.8634 80.9865 54.6081 80.9865 60.461Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Advantage1;
