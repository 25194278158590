import { isNil } from '@fxts/core';
import { ProductBillingType } from '../constants/constants';
import { countDaysOfWeekBetweenDates, getLastDayOfMonth } from './time';

export const changeCurrencyUnit = (amount: number) => {
    if (amount < 100000000) {
        const quotient = Math.floor(amount / 10000);
        let remainder = amount % 10000 > 0 ? String(amount % 10000) : '';
        if (quotient === 0) return remainder;

        return `${quotient}만${remainder}`;
    }
    return String(amount);
};

export const getDiscountPercent = (price, originalPrice) => {
    if (isNil(price)) return 0;
    if (isNil(originalPrice)) return 0;
    return Math.round(((originalPrice - price) / originalPrice) * 100);
};

export const getDiscountedPrice = (price, originalPrice) => {
    if (isNil(price)) return 0;
    if (isNil(originalPrice)) return 0;
    return Math.floor(originalPrice - price);
};

export const getPriceFromProduct = (product?: any | null, firstDateOfClass?: Date, billingDay?: number): number => {
    if (product === undefined || product === null) return 0;
    if (product.billingType === ProductBillingType.MONTHLY_BILLING) {
        if (isNil(firstDateOfClass)) return product.price;
        if (isNil(billingDay)) return product.price;
        const priceByDay = product.price / getLastDayOfMonth(firstDateOfClass);
        const price = countDaysOfWeekBetweenDates(firstDateOfClass, billingDay) * priceByDay;

        // 원래 금액에서 십의 자리까지 버리고 백의 자리에서 절사함
        return Math.floor(price / 100) * 100;
    }
    return product.price ?? 0;
};

export const getTotalPrice = (products: any[], firstDateOfClass?: Date, billingDay?: number): number => {
    return products.reduce((acc: number, product: any) => {
        return acc + getPriceFromProduct(product, firstDateOfClass, billingDay);
    }, 0);
};

export const getTotalMonthlyBillingPrice = (products: any[]): number => {
    return products.reduce((acc: number, product: any) => {
        return acc + product.price;
    }, 0);
};
