import * as React from 'react';

function Strategy3(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16221" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <rect y="0.5" width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16221)">
                <path
                    d="M83.0078 34.3751L77.1327 4.5L70.1461 12.8785C52.0007 3.40452 29.0698 7.46715 15.4033 23.8608C-0.398653 42.8087 2.08169 71.1534 20.9334 87.0406C39.7851 102.928 67.9779 100.434 83.7798 81.4806C91.1004 72.7003 94.4896 61.9052 94.1556 51.2642L79.4816 51.0275C79.8813 58.3986 77.6474 65.9348 72.5662 72.0287C61.9495 84.7616 43.0047 86.4406 30.3346 75.7665C17.6646 65.0925 16.0001 46.04 26.6169 33.3072C35.0599 23.1837 48.7483 20.0844 60.378 24.5929L52.718 33.7751L83.0023 34.3751H83.0078Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Strategy3;
