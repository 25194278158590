import React from 'react';
import { PurchaseModule } from './PurchaseModule';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: sticky;
    width: 100%;
    bottom: 0;
    padding: 1rem 1.5rem;
    gap: 2rem;
    border-radius: 1rem;
    background:
        linear-gradient(#ffffff 0 0) padding-box,
        linear-gradient(to right, #9c20aa, #fb3570) border-box;
    border: 2px solid transparent;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const PurchaseModuleBottomPannel = (props) => {
    const { purchase } = props;

    return (
        <Wrapper>
            <PurchaseModule purchase={purchase} />
        </Wrapper>
    );
};
