import React, { useContext, useEffect, useRef } from 'react';
import { PaymentDirectInfoContext, PaymentDirectInfoContextStore } from '../../contexts/payment-direct.context';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { Footer } from '../../components/common/Footer';
import styled from 'styled-components';
import { moveToPurchasePage } from '../../utils/move-to-purchase-page';

const MobileImage = styled.img`
    display: block;
    width: 100%;
`;

const DesktopImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 60px 0px;
    background: linear-gradient(96.2deg, #293534 32.01%, rgba(41, 53, 52, 0) 105.93%), linear-gradient(311.67deg, #10d46c 1.13%, #0da8ff 104.35%),
        linear-gradient(318.19deg, #10d46c 17.91%, #00a64e 91.44%);
`;

const DesktopImage = styled.img`
    display: block;
    width: ${(props) => props.desktopContentWidth};
`;

export const PaymentDirect = (props) => {
    const { paymentPage } = props;
    useEffect(() => {
        googleAnalyticsConfig('직접 결제 페이지', '/direct');
    }, []);
    return (
        <PaymentDirectInfoContext>
            <PaymentDirectContent paymentPage={paymentPage} />
        </PaymentDirectInfoContext>
    );
};

const PaymentDirectContent = (props) => {
    const { paymentPage } = props;
    const { isMobile, scrollY, readsProductListSection, setReadsProductListSection, desktopContentWidth } = useContext(PaymentDirectInfoContextStore);
    const productListSectionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (readsProductListSection) return;
        const productListSectionTop = productListSectionRef?.current?.offsetTop ?? 0;
        if (scrollY <= productListSectionTop) return;

        setReadsProductListSection(true);
        googleAnalyticsEvent('scroll', 'scroll', 'productionList');
    }, [readsProductListSection, scrollY, setReadsProductListSection]);

    if (isMobile) {
        return (
            <>
                <MobileImage src="/images/payment-direct/main/mobile/2.png" alt="2" />
                <div ref={productListSectionRef}>
                    <PaymentCardListMobile paymentPage={paymentPage} />
                </div>
                <Footer />
            </>
        );
    } else {
        return (
            <>
                <DesktopImageWrapper>
                    <DesktopImage src="/images/payment-direct/main/desktop/2.png" alt="2" desktopContentWidth={desktopContentWidth} />
                </DesktopImageWrapper>
                <div ref={productListSectionRef}>
                    <PaymentCardListDesktop paymentPage={paymentPage} />
                </div>
                <Footer />
            </>
        );
    }
};

const PaymentCardListDesktop = (props) => {
    const { paymentPage } = props;
    const { desktopContentWidth } = useContext(PaymentDirectInfoContextStore);

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#FFFFFF' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 40, width: desktopContentWidth, padding: '70px 0' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '40px',
                                fontWeight: 600,
                                lineHeight: '48px',
                                letterSpacing: '-0.03em',
                                textAlign: 'center',
                                color: '#A8A8A8',
                            }}
                        >
                            전국 기출로 내신대비 100%
                        </div>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '65px',
                                fontWeight: 800,
                                lineHeight: '80px',
                                letterSpacing: '-0.09em',
                                textAlign: 'center',
                                color: '#000000',
                            }}
                        >
                            초등 · 중학생 PT
                        </div>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '30px',
                                fontWeight: 500,
                                letterSpacing: '-0.09em',
                                textAlign: 'center',
                                color: '#A8A8A8',
                            }}
                        >
                            초등 ~ 예비중3
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '40px', width: '100%' }}>
                        <div style={{ flex: 1 }}>
                            <img
                                onClick={() => moveToPurchasePage(paymentPage, 0)}
                                style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                                src="/images/payment-direct/main/desktop/middle-math.png"
                                alt="middle-math"
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <img
                                onClick={() => moveToPurchasePage(paymentPage, 1)}
                                style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                                src="/images/payment-direct/main/desktop/middle-english.png"
                                alt="middle-english"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#F5F5F5' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 40, width: desktopContentWidth, padding: '70px 0' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '40px',
                                fontWeight: 600,
                                lineHeight: '48px',
                                letterSpacing: '-0.03em',
                                textAlign: 'center',
                                color: '#A8A8A8',
                            }}
                        >
                            약한 부분 집중 공략
                        </div>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '65px',
                                fontWeight: 800,
                                lineHeight: '80px',
                                letterSpacing: '-0.09em',
                                textAlign: 'center',
                                color: '#000000',
                            }}
                        >
                            고등학생 PT
                        </div>
                        <div
                            style={{
                                fontFamily: 'Pretendard',
                                fontSize: '30px',
                                fontWeight: 500,
                                letterSpacing: '-0.09em',
                                textAlign: 'center',
                                color: '#A8A8A8',
                            }}
                        >
                            예비고1 ~ 예비고2
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '40px', width: '100%' }}>
                        <div style={{ flex: 1 }}>
                            <img
                                onClick={() => moveToPurchasePage(paymentPage, 2)}
                                style={{ display: 'block', width: '100%', cursor: 'pointer' }}
                                src="/images/payment-direct/main/desktop/high-math.png"
                                alt="high-math"
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <img
                                onClick={() => moveToPurchasePage(paymentPage, 3)}
                                style={{ display: 'block', width: '100%', cursor: 'pointer' }}
                                src="/images/payment-direct/main/desktop/high-english.png"
                                alt="high-english"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const PaymentCardListMobile = (props) => {
    const { paymentPage } = props;

    return (
        <div>
            <div style={{ display: 'block', width: '100%', flexDirection: 'column', alignItems: 'center', padding: '11vw 5vw', backgroundColor: '#FFFFFF' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '5vw',
                            fontWeight: 600,
                            lineHeight: '6.2vw',
                            letterSpacing: '-0.03em',
                            textAlign: 'left',
                            color: '#A8A8A8',
                        }}
                    >
                        전국 기출로 내신대비 100%
                    </div>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '9.5vw',
                            fontWeight: 800,
                            lineHeight: '11vw',
                            letterSpacing: '-0.03em',
                            textAlign: 'left',
                            color: '#000000',
                        }}
                    >
                        초등 · 중학생 PT
                    </div>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '30px',
                            fontWeight: 500,
                            letterSpacing: '-0.09em',
                            textAlign: 'center',
                            color: '#A8A8A8',
                        }}
                    >
                        초등 ~ 예비중3
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '5vw' }}>
                    <img
                        onClick={() => moveToPurchasePage(paymentPage, 0)}
                        style={{ display: 'block', width: '49.5%' }}
                        src="/images/payment-direct/main/mobile/middle-math.png"
                        alt="middle-math"
                    />
                    <img
                        onClick={() => moveToPurchasePage(paymentPage, 1)}
                        style={{ display: 'block', width: '49.5%', marginLeft: '1%' }}
                        src="/images/payment-direct/main/mobile/middle-english.png"
                        alt="middle-english"
                    />
                </div>
            </div>
            <div style={{ display: 'block', width: '100%', flexDirection: 'column', alignItems: 'center', padding: '11vw 5vw', backgroundColor: '#F5F5F5' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '5vw',
                            fontWeight: 600,
                            lineHeight: '6.2vw',
                            letterSpacing: '-0.03em',
                            textAlign: 'center',
                            color: '#A8A8A8',
                        }}
                    >
                        약한 부분 집중 공략
                    </div>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '9.5vw',
                            fontWeight: 800,
                            lineHeight: '11vw',
                            letterSpacing: '-0.03em',
                            textAlign: 'center',
                            color: '#000000',
                        }}
                    >
                        고등학생 PT
                    </div>
                    <div
                        style={{
                            fontFamily: 'Pretendard',
                            fontSize: '30px',
                            fontWeight: 500,
                            letterSpacing: '-0.09em',
                            textAlign: 'center',
                            color: '#A8A8A8',
                        }}
                    >
                        예비고1 ~ 예비고2
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '5vw' }}>
                    <img
                        onClick={() => moveToPurchasePage(paymentPage, 2)}
                        style={{ display: 'block', width: '49.5%' }}
                        src="/images/payment-direct/main/mobile/high-math.png"
                        alt="high-math"
                    />
                    <img
                        onClick={() => moveToPurchasePage(paymentPage, 3)}
                        style={{ display: 'block', width: '49.5%', marginLeft: '1%' }}
                        src="/images/payment-direct/main/mobile/high-english.png"
                        alt="high-english"
                    />
                </div>
            </div>
        </div>
    );
};
