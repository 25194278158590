import { DAYS_OF_MONTH } from '../constants/constants';

export const validateFirstDateOfClass = (date: Date) => {
    // 어느 날짜든 개강일로 설정할 수 있도록 수정
    return true;
    // return DateTime.fromJSDate(date).diff(DateTime.now().minus({ day: 1 })).milliseconds > 0;
};

export const validateMonthlyBillingDay = (day: number) => {
    return DAYS_OF_MONTH.includes(Number(day));
};
