import React, { createContext, useEffect, useState } from 'react';
import { getDesktopContentWidth, isMobileByScreenWidth } from '../utils/screen';

export const PaymentDirectInfoContextStore = createContext({} as any);

export const PaymentDirectInfoContext = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scrollY, setScrollY] = useState(window.scrollY);
    const [readsProductListSection, setReadsProductListSection] = useState(false);
    const isMobile = isMobileByScreenWidth(width);
    const desktopContentWidth = getDesktopContentWidth(width);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
        window.addEventListener('scroll', () => setScrollY(window.scrollY));
    }, []);

    const PurchaseInfo = { isMobile, scrollY, readsProductListSection, setReadsProductListSection, desktopContentWidth };

    return <PaymentDirectInfoContextStore.Provider value={PurchaseInfo}>{props.children}</PaymentDirectInfoContextStore.Provider>;
};
