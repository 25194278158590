import * as React from 'react';

function Advantage4(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16269" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <rect y="0.5" width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16269)">
                <path
                    d="M38.8655 75.8073V84.1457C38.8655 85.78 38.0872 87.3143 36.7753 88.2815L33.3733 90.7941C32.4727 91.4612 32.9508 92.8843 34.0626 92.8843H65.9152C67.0269 92.8843 67.505 91.4612 66.6045 90.7941L63.2024 88.2815C61.8905 87.3143 61.1123 85.7689 61.1123 84.1457V75.8073M4 63.889H96M61.09 62.4882V58.23M56.3205 45.7336L65.526 48.791C68.0609 49.6248 69.7619 51.9929 69.7619 54.6612V62.4993M38.91 62.4882V58.23M43.9686 45.6447L34.474 48.791C31.9391 49.6248 30.2381 51.9929 30.2381 54.6612V62.4882M9.19202 8.5H90.808C93.6755 8.5 96 10.8245 96 13.692V70.6153C96 73.4828 93.6755 75.8073 90.808 75.8073H9.19202C6.32455 75.8073 4 73.4828 4 70.6153V13.692C4 10.8245 6.32455 8.5 9.19202 8.5ZM58.9109 28.2675C58.9109 34.2378 54.9752 41.0752 50.1279 41.0752C45.2805 41.0752 41.3448 34.2378 41.3448 28.2675C41.3448 22.2972 45.2805 18.8507 50.1279 18.8507C54.9752 18.8507 58.9109 22.2972 58.9109 28.2675Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Advantage4;
