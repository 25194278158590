export const getIamportDisplay = (price) => {
    if (price >= 1100000) return { display: { card_quota: calculateCardQuota(price) } };
    return {};
};

export const calculateCardQuota = (price) => {
    const quotaTo12Month = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const quotaTo18Month = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    if (price >= 2000000) return quotaTo18Month;
    if (price >= 1100000) return quotaTo12Month;
    return quotaTo12Month;
};
