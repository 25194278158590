import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/purchase-page';
import {
    PurchaseSectionDescription,
    PurchaseSectionInnerWrapper,
    PurchaseSectionTitle,
    PurchaseSectionTitleWrapper,
    PurchaseSectionWrapper,
} from './SectionCommonStyles';
import { processListDefault } from './cosntants/processList';

const SectionStepListWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
`;

const SectionStepItem = styled.div`
    width: 100%;
    min-width: 20rem;
    display: flex;
    gap: 2rem;
    background-color: #ffffff;
    padding: 1.5rem;
    font-size: 1.5rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 1rem;
        padding: 1rem;
        font-size: 1rem;
    }
`;

const StepNumber = styled.span`
    color: ${(props) => props.color};
`;

const StepBar = styled.span`
    background-color: ${(props) => props.color};
    width: 3px;
    height: 1.25rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 2px;
        height: 0.875rem;
    }
`;

export const PurchaseProcessSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseSectionWrapper color={material.color + '88'}>
            <PurchaseSectionInnerWrapper>
                <PurchaseSectionTitleWrapper>
                    <PurchaseSectionDescription>결제 이후 어떻게 진행되나요?</PurchaseSectionDescription>
                    <PurchaseSectionTitle>PT등록 절차 안내</PurchaseSectionTitle>
                </PurchaseSectionTitleWrapper>
                <SectionStepListWrapper>
                    {processListDefault.map((item, i) => {
                        return (
                            <SectionStepItem key={i}>
                                <StepNumber color={material.color}>STEP 0{i + 1}</StepNumber>
                                <StepBar color={material.color} />
                                <div>{item}</div>
                            </SectionStepItem>
                        );
                    })}
                </SectionStepListWrapper>
            </PurchaseSectionInnerWrapper>
        </PurchaseSectionWrapper>
    );
};
