import { MAX_DESKTOP_CONTENT_WIDTH, MAX_MOBILE_WIDTH, MIDDLE_DESKTOP_WIDTH, MIN_DESKTOP_CONTENT_WIDTH } from '../../../constants/constants';
import styled from 'styled-components';

export const PurchaseSectionWrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    padding: 3rem 0;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 2rem 1rem;
    }
`;

export const PurchaseSectionInnerWrapper = styled.div`
    width: ${MAX_DESKTOP_CONTENT_WIDTH}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 1rem;
    }
`;

export const PurchaseSectionTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props) => (props.color ? props.color : '#333333')};
`;

export const PurchaseSectionTitle = styled.div`
    font-weight: 800;
    font-size: 2.5rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 2rem;
    }
`;

export const PurchaseSectionDescription = styled.div`
    font-weight: 400;
    font-size: 1.25rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;
