import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/purchase-page';
import {
    PurchaseSectionDescription,
    PurchaseSectionInnerWrapper,
    PurchaseSectionTitle,
    PurchaseSectionTitleWrapper,
    PurchaseSectionWrapper,
} from './SectionCommonStyles';
import { personalTeachingDefault } from './cosntants/personalTeachingList';

const SectionBodyWrapper = styled.div`
    width: 100%;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
`;

const SectionBodyTitle = styled.div`
    font-size: 1.5rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

const SectionBodyCardWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const SectionBodyCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    background-color: ${(props) => props.color};
    border-radius: 0.5rem;
    padding: 2rem;
    gap: 1rem;
    border: 2px solid ${(props) => props.color};

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 1rem;
        gap: 0.5rem;
    }
`;

const SectionBodyCardTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 800;
    color: ${(props) => props.color};

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

const SectionBodyCardDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 0.25rem;
        font-size: 0.75rem;
    }
`;

const PlusIcon = styled.div`
    color: #cbcbcb;
    font-weight: 900;
    font-size: 4rem;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 2rem;
    }
`;

export const PurchasePersonalTeachingSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseSectionWrapper color={'#ffffff'}>
            <PurchaseSectionInnerWrapper>
                <PurchaseSectionTitleWrapper>
                    <PurchaseSectionDescription>{personalTeachingDefault.description}</PurchaseSectionDescription>
                    <PurchaseSectionTitle>{personalTeachingDefault.title}</PurchaseSectionTitle>
                </PurchaseSectionTitleWrapper>
                <SectionBodyWrapper>
                    <SectionBodyTitle>{personalTeachingDefault.cardTitle}</SectionBodyTitle>
                    <SectionBodyCardWrapper>
                        <SectionBodyCard color={material.color + '33'}>
                            <SectionBodyCardTitle>{personalTeachingDefault.firstCard.title}</SectionBodyCardTitle>
                            <SectionBodyCardDescription>
                                {personalTeachingDefault.firstCard.descriptions.map((description, i) => (
                                    <div key={i}>{description}</div>
                                ))}
                            </SectionBodyCardDescription>
                        </SectionBodyCard>
                        <PlusIcon>+</PlusIcon>
                        <SectionBodyCard color={material.color + '33'}>
                            <SectionBodyCardTitle>{personalTeachingDefault.secondCard.title}</SectionBodyCardTitle>
                            <SectionBodyCardDescription>
                                {personalTeachingDefault.secondCard.descriptions.map((description, i) => (
                                    <div key={i}>{description}</div>
                                ))}
                            </SectionBodyCardDescription>
                        </SectionBodyCard>
                    </SectionBodyCardWrapper>
                </SectionBodyWrapper>
            </PurchaseSectionInnerWrapper>
        </PurchaseSectionWrapper>
    );
};
