import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/purchase-page';
import {
    PurchaseSectionDescription,
    PurchaseSectionInnerWrapper,
    PurchaseSectionTitle,
    PurchaseSectionTitleWrapper,
    PurchaseSectionWrapper,
} from './SectionCommonStyles';
import Advantage1 from './assets/Advantage1';
import Advantage2 from './assets/Advantage2';
import Advantage3 from './assets/Advantage3';
import Advantage4 from './assets/Advantage4';

const SectionBodyWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 0.5rem;
    }
`;

const SectionBodyCard = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 2rem;
    gap: 1rem;
    border: 2px solid ${(props) => props.color};

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        gap: 0.5rem;
    }
`;

const SectionBodyCardDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const SectionBodyCardOrderNumber = styled.div`
    font-size: 1rem;
    font-weight: 800;
    color: #999999;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        display: none;
        font-size: 0.75rem;
    }
`;

const SectionBodyCardDescription = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 0.75rem;
    }
`;

export const PurchaseAdvantageSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseSectionWrapper color={'#f9f9f9'}>
            <PurchaseSectionInnerWrapper>
                <PurchaseSectionTitleWrapper>
                    <PurchaseSectionDescription>과외를 뛰어넘은 3세대 교육법</PurchaseSectionDescription>
                    <PurchaseSectionTitle>PT만의 특장점</PurchaseSectionTitle>
                </PurchaseSectionTitleWrapper>
                <SectionBodyWrapper>
                    <SectionBodyCard color={material.color + '99'}>
                        <Advantage1 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>01</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>
                                    <b>초정밀 진단고사</b>로
                                </div>
                                <div>취약점 완벽 분석</div>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                    <SectionBodyCard color={material.color + '99'}>
                        <Advantage2 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>02</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>유명 학원가</div>
                                <div>
                                    <b>현직 1타 강사</b>의 강의
                                </div>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                    <SectionBodyCard color={material.color + '99'}>
                        <Advantage3 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>03</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>실시간 AI 기반으로</div>
                                <div>
                                    <b>출석부터 풀이까지 관리</b>
                                </div>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                    <SectionBodyCard color={material.color + '99'}>
                        <Advantage4 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>04</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>
                                    <b>별도 기기, 위약금 없이</b>
                                </div>
                                <div>PC, 태블릿으로 진행 가능</div>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                </SectionBodyWrapper>
            </PurchaseSectionInnerWrapper>
        </PurchaseSectionWrapper>
    );
};
