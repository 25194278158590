import { PaymentSection } from './PaymentSection';
import React from 'react';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '../../constants/constants';
import styled from 'styled-components';
import { TotalShopSection } from '../../constants/payment-total-shop-page';

const SectionListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 0;
    }
`;

const SectionList = styled.div`
    width: ${MAX_TABLET_WIDTH}px;
    padding: 3rem 0;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        grid-template-columns: 1fr;
        padding: 1rem;
    }
`;

interface PaymentSectionListProps {
    sections: TotalShopSection[];
}

export const PaymentSectionList = ({ sections }: PaymentSectionListProps) => {
    return (
        <SectionListWrapper>
            <SectionList>
                {sections.map((item, key) => {
                    return (
                        <PaymentSection
                            title={item.title}
                            description={item.description}
                            math={item.math}
                            english={item.english}
                            korean={item.korean}
                            key={key}
                        />
                    );
                })}
            </SectionList>
        </SectionListWrapper>
    );
};
