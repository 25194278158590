import React from 'react';
import { PurchaseModule } from './PurchaseModule';
import styled from 'styled-components';

const Wrapper = styled.div`
    padding: 1rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const PurchaseModuleSection = (props) => {
    const { purchase } = props;

    return (
        <Wrapper>
            <PurchaseModule purchase={purchase} />
        </Wrapper>
    );
};
