import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../constants/constants';
import { PurchaseConfigureItem } from '../../constants/purchase-page';

const PurchaseHeaderWrapper = styled.div`
    padding: 16px 0px;
    background-color: ${(props) => props.color};
    display: flex;
    justify-content: center;
`;

const PurchaseHeaderImage = styled.img`
    display: block;
    width: 480px;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 320px;
    }
`;

const PurchaseHeaderText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    background-image: url('/images/payment-direct/purchase/hero-back-image.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 4rem;
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-break: keep-all;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 2rem;
    }
`;

const PurchaseHeaderTitle = styled.div`
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.2;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 2rem;
    }
`;

const PurchaseHeaderDescription = styled.div`
    font-size: 1.5rem;
    font-weight: 600;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

export const PurchaseHeader = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseHeaderWrapper color={material.color}>
            {material.header.type === 'image' ? (
                <PurchaseHeaderImage src={material.header.url} alt="1" />
            ) : (
                <PurchaseHeaderText>
                    <PurchaseHeaderDescription>{material.header.description}</PurchaseHeaderDescription>
                    <PurchaseHeaderTitle>{material.header.title}</PurchaseHeaderTitle>
                </PurchaseHeaderText>
            )}
        </PurchaseHeaderWrapper>
    );
};
