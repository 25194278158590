//react
import React, { useState, useEffect } from 'react';
import { Swiper, Slide } from 'react-dynamic-swiper';
import 'react-dynamic-swiper/lib/styles.css';
//material-ui
import CircularProgress from '@material-ui/core/CircularProgress';
import { Dialog, TextField, Button, Checkbox } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ChatIcon from '@material-ui/icons/ChatBubble';
//etc
import { studyVideos, reviewVideos } from '../../constants/video-list';
import Terms from '../../components/common/Terms';
import Deadline from '../../components/common/Deadline';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { ajax } from '../../utils/jQuery';
import { REST_URL } from '../../constants/constants';

function Introduction(props) {
    const contentMaxWidth = 1920;

    const [isRegisterDialogOpen, setRegisterDialogOpen] = useState(false);
    const [isTermDialogOpen, setTermDialogOpen] = useState(false);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [progress, setProgress] = useState(false);
    const [premiumClass, setPremiumClass] = useState<string[]>([]);
    const [selectedReviewVideo, setSelectedReviewVideo] = useState(0);
    const [selectedStudyVideo, setSelectedStudyVideo] = useState(0);
    const [contentWidth, setContentWidth] = useState((window.innerWidth < contentMaxWidth ? window.innerWidth : contentMaxWidth) / 100);

    const onKeyDown = (event) => {
        if (event.key === 'Escape') {
            closeRegisterDialog();
        }
    };
    const openRegisterDialog = () => {
        googleAnalyticsEvent('click', 'button', 'register_consultation');
        setRegisterDialogOpen(true);
    };

    const openPaymentPage = () => {
        googleAnalyticsEvent('click', 'button', 'payment_page');
        window.location.href = 'http://payment.mildang.kr/direct';
    };

    const closeRegisterDialog = () => {
        setName('');
        setPhoneNumber('');
        setRegisterDialogOpen(false);
    };

    const openTermDialog = () => setTermDialogOpen(true);

    const closeTermDialog = () => setTermDialogOpen(false);

    const onChange = (stateKey, event) => {
        if (stateKey === 'name') {
            setName(event.target.value);
        } else if (stateKey === 'phoneNumber') {
            setPhoneNumber(event.target.value);
        }
    };

    const getPremiumClass = () => {
        ajax({
            type: 'GET',
            url: REST_URL + '/configs/3',
            success: function (data) {
                const count = data.data.config.items.class;
                const classArray: string[] = [];
                if (count >= 100) {
                    classArray.push(String(count)[0]);
                    classArray.push(String(count)[1]);
                    classArray.push(String(count)[2]);
                } else if (count >= 10) {
                    classArray.push('0');
                    classArray.push(String(count)[0]);
                    classArray.push(String(count)[1]);
                } else {
                    classArray.push('0');
                    classArray.push('0');
                    classArray.push(String(count)[0]);
                }
                setPremiumClass(classArray);
            },
            contentType: 'application/json',
            dataType: 'json',
            headers: {
                Authorization:
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTkxNjY5NywiaWF0IjoxNTk4MjMwODUyLCJleHAiOjE2MDY4NzA4NTJ9.5IwiarPuIZFuJLXaeebKqXAgFpoHGStM9dLlC0N7ens',
            },
        });
    };

    const register = () => {
        if (!(name === '' || /^010[0-9]{8}$/.test(phoneNumber) === false)) {
            setProgress(true);

            const params = {
                name: name,
                applicantId: phoneNumber,
                type: 1,
                studyTime: 0,
                path: 1032,
                counselingTime: '아무 때나 가능',
            };

            ajax({
                type: 'POST',
                url: REST_URL + '/trials',
                data: JSON.stringify(params),
                success: function () {
                    googleAnalyticsEvent('click', 'button', 'register_consultation_finish');
                    setProgress(false);
                    closeRegisterDialog();
                    alert('신청완료!\n\n상담은 순차적으로 진행되며, 최대한 빠르게 연락드리도록 하겠습니다 ^^');
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        } else {
            alert('이름과 전화번호를 다시 확인해주세요!');
        }
    };

    useEffect(() => {
        getPremiumClass();
    }, []);

    useEffect(() => {
        const handleContentWidth = () => setContentWidth((window.innerWidth < contentMaxWidth ? window.innerWidth : contentMaxWidth) / 100);
        window.addEventListener('resize', handleContentWidth);
        return () => window.removeEventListener('resize', handleContentWidth);
    }, []);

    useEffect(() => {
        googleAnalyticsConfig('안내 페이지', '/introduction');
    });

    const dialogs = (
        <div>
            <Dialog onKeyDown={onKeyDown} open={isRegisterDialogOpen} onBackdropClick={closeRegisterDialog}>
                <div style={{ padding: 24 }}>
                    <div style={{ marginBottom: 16 }}>
                        <span style={{ fontSize: 18, fontWeight: 'bold' }}>카카오톡 상담신청</span>
                        <br />
                        <span style={{ fontSize: 12 }}>*현재 상담 대기인원이 몰려 상담 진행까지 최대 2일 정도 걸릴 수 있습니다</span>
                    </div>
                    <div style={{ margin: '16px 0px' }}>
                        <TextField
                            style={{ marginBottom: 8 }}
                            variant="outlined"
                            placeholder="이름"
                            name="name"
                            fullWidth={true}
                            onChange={onChange.bind(null, 'name')}
                            error={!/^[ㄱ-ㅎㅏ-ㅣ가-힣a-z0-9_-].{1,10}$/.test(name)}
                            value={name}
                        />
                        <TextField
                            variant="outlined"
                            placeholder="연락처"
                            name="phoneNumber"
                            type="number"
                            fullWidth={true}
                            onChange={onChange.bind(null, 'phoneNumber')}
                            error={!/^(?=.*[0-9]).{10,11}$/.test(phoneNumber)}
                            value={phoneNumber}
                        />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                        <Checkbox style={{ width: 20, height: 20, marginRight: 4 }} size={'small'} defaultChecked={true} />
                        <span style={{ fontSize: 12 }}>개인정보수집 이용 및 제공에 관한 동의</span>
                        <span
                            style={{
                                cursor: 'pointer',
                                fontSize: 12,
                                textDecoration: 'underline',
                                marginLeft: 4,
                            }}
                            onClick={openTermDialog}
                        >
                            보기
                        </span>
                    </div>
                    <Button
                        style={{
                            width: '100%',
                            color: '#fff',
                            backgroundColor: 'rgb(231,65,176)',
                            borderRadius: 0,
                        }}
                        onClick={register}
                    >
                        상담 등록
                    </Button>
                    <div style={{ fontSize: 10, marginTop: 8 }}>상담 등록 시 카카오톡을 통해 커리큘럼 및 학습 방법에 대한 안내를 진행해드립니다.</div>
                    {progress && (
                        <CircularProgress
                            style={{
                                position: 'fixed',
                                left: '50%',
                                top: '50%',
                                marginLeft: -20,
                                marginTop: -20,
                                zIndex: 2,
                            }}
                        />
                    )}
                </div>
            </Dialog>
            <Dialog onKeyDown={onKeyDown} open={isTermDialogOpen} onBackdropClick={closeTermDialog}>
                <Terms />
                <Button
                    style={{
                        position: 'sticky',
                        bottom: 0,
                        width: '100%',
                        color: '#fff',
                        backgroundColor: 'rgb(231,65,176)',
                        borderRadius: 0,
                    }}
                    onClick={closeTermDialog}
                >
                    닫기
                </Button>
            </Dialog>
        </div>
    );

    const swiperPrevButton = (width, height, color) => {
        return (
            <div className="swiper-button-prev swiper-navigation-button" onFocus={(e) => (e.target.style.outline = 'none')} style={{ width: 0 }}>
                <KeyboardArrowLeftIcon
                    style={{
                        marginLeft: width * 0.5,
                        color: color,
                        width: width,
                        height: height,
                    }}
                />
            </div>
        );
    };

    const swiperNextButton = (width, height, color) => {
        return (
            <div className="swiper-button-next swiper-navigation-button" onFocus={(e) => (e.target.style.outline = 'none')} style={{ width: 0 }}>
                <KeyboardArrowRightIcon
                    style={{
                        marginRight: width * 0.5,
                        color: color,
                        width: width,
                        height: height,
                    }}
                />
            </div>
        );
    };

    const applyBar = (
        <div className={'apply_bar'}>
            모집 마감 까지&nbsp;
            <Deadline />
            &nbsp;
            <Button
                style={{
                    color: '#fff',
                    fontSize: '14px',
                    backgroundColor: 'rgb(231,65,176)',
                }}
                onClick={openPaymentPage}
            >
                선착순 특가 등록
            </Button>
        </div>
    );

    const footer = (
        <div className={'footer'}>
            ihateflyingbugs co.,ltd
            <br />
            사업자등록번호 : 101-86-69359 | 대표이사: 박찬용
            <br />
            통신판매업신고 : 2013-서울마포-1269
            <br />
            주소 : 서울특별시 마포구 공덕동 467 롯데캐슬프레지던트 2203호
            <br />
            고객센터 : 1899-0508 | E-mail : cs@ihateflyingbugs.com
            <br />
            &copy; ihateflyingbugs all right reserved.
            <br />
        </div>
    );

    const applyButton = (
        <Button
            style={{
                position: 'fixed',
                left: 20,
                bottom: 70,
                fontSize: props.isMobile ? 16 : 20,
                padding: '8px 16px',
                color: '#000',
                backgroundColor: 'rgb(235,252,100)',
                borderRadius: 40,
                zIndex: 2,
            }}
            onClick={openRegisterDialog}
        >
            <div className={'apply_button'}>
                <ChatIcon className={'chat_icon'} />
                상담신청
            </div>
        </Button>
    );

    if (props.isMobile) {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: `${contentMaxWidth}px 0 1`, margin: '0 auto' }}>
                        <div style={{ position: 'relative' }}>
                            <img className={'background'} src="/images/introduction/m_background_1.jpg" alt="m_background_1.jpg" />
                            <div
                                className={'digital'}
                                style={{
                                    position: 'absolute',
                                    top: '10.7%',
                                    left: '23%',
                                    width: '53.5%',
                                    height: 22.5 * contentWidth,
                                    fontSize: 10 * contentWidth,
                                }}
                            >
                                {premiumClass.map((item, key) => (
                                    <span key={key}>{item}</span>
                                ))}
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <img className={'background'} src="/images/introduction/m_background_2.jpg" alt="m_background_2.jpg" />
                            <div className={'content mobile'} style={{ position: 'absolute', top: '20%', width: '100%' }}>
                                <iframe
                                    style={{
                                        boxSizing: 'border-box',
                                        width: '100%',
                                        height: 55.9 * contentWidth,
                                        border: `${1 * contentWidth}px solid yellow`,
                                    }}
                                    title="gJ0JsstWvqc"
                                    src={`${studyVideos[selectedStudyVideo].url}?autoplay=1;rel=0`}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                                <h2
                                    style={{
                                        margin: '1% 0 5% 0',
                                        color: '#fff',
                                        fontSize: contentWidth * 4,
                                    }}
                                >
                                    {studyVideos[selectedStudyVideo].title}
                                </h2>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    {studyVideos.map((video, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '24%',
                                                }}
                                                onClick={() => setSelectedStudyVideo(index)}
                                            >
                                                <img
                                                    style={{
                                                        display: 'block',
                                                        width: '100%',
                                                        height: 15 * contentWidth,
                                                        border: index === selectedStudyVideo ? `${0.5 * contentWidth}px solid yellow` : '',
                                                    }}
                                                    alt={String(index)}
                                                    src={video.thumbnail}
                                                />
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        padding: '4%',
                                                        color: '#fff',
                                                        fontSize: contentWidth * 3,
                                                    }}
                                                >
                                                    {video.subtitle}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <img className={'background'} src="/images/introduction/m_background_3.jpg" alt="m_background_3.jpg" />
                            <div className={'content mobile'} style={{ position: 'absolute', top: '62.5%', width: '100%' }}>
                                <iframe
                                    style={{
                                        boxSizing: 'border-box',
                                        width: '100%',
                                        height: 55.9 * contentWidth,
                                        border: `${1 * contentWidth}px solid rgb(146,99,246)`,
                                    }}
                                    title="gJ0JsstWvqc"
                                    src={`${reviewVideos[selectedReviewVideo].url}?autoplay=1;rel=0`}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                                <h2
                                    style={{
                                        margin: '1% 0 5% 0',
                                        fontSize: contentWidth * 4,
                                    }}
                                >
                                    {reviewVideos[selectedReviewVideo].title}
                                </h2>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 11 * contentWidth,
                                    }}
                                >
                                    <Swiper
                                        style={{ width: '100%' }}
                                        pagination={false}
                                        prevButton={swiperPrevButton(0.02 * contentMaxWidth, 0.02 * contentMaxWidth, 'rgb(146,99,246)')}
                                        nextButton={swiperNextButton(0.02 * contentMaxWidth, 0.02 * contentMaxWidth, 'rgb(146,99,246)')}
                                    >
                                        {[1, 2].map((video, key) => {
                                            return (
                                                <Slide key={key}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-around',
                                                                width: '80%',
                                                            }}
                                                        >
                                                            {[1, 2, 3].map((num, _key) => {
                                                                const index = key * 3 + _key;
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            width: '32%',
                                                                        }}
                                                                        onClick={() => setSelectedReviewVideo(index)}
                                                                    >
                                                                        <img
                                                                            style={{
                                                                                width: '100%',
                                                                                height: 17 * contentWidth,
                                                                                cursor: 'pointer',
                                                                                border:
                                                                                    index === selectedReviewVideo
                                                                                        ? `${0.5 * contentWidth}px solid rgb(146,99,246)`
                                                                                        : '',
                                                                            }}
                                                                            alt={String(index)}
                                                                            src={reviewVideos[index].thumbnail}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                display: 'inline-block',
                                                                                padding: '4%',
                                                                                fontSize: contentWidth * 3,
                                                                            }}
                                                                        >
                                                                            {reviewVideos[index].subtitle}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </Slide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                            <div
                                className={'content mobile'}
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '82.5%',
                                    width: '100%',
                                    zIndex: 1,
                                    justifyContent: 'center',
                                }}
                            >
                                <Swiper
                                    style={{ width: '100%' }}
                                    pagination={false}
                                    prevButton={swiperPrevButton(0.02 * contentMaxWidth, 0.02 * contentMaxWidth, 'yellow')}
                                    nextButton={swiperNextButton(0.02 * contentMaxWidth, 0.02 * contentMaxWidth, 'yellow')}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((num, key) => {
                                        return (
                                            <Slide key={key}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            cursor: 'pointer',
                                                            width: '80%',
                                                            height: 100 * contentWidth,
                                                            border: `${1 * contentWidth}px solid yellow`,
                                                        }}
                                                        alt={String(key)}
                                                        src={`/images/introduction/review${num}.png`}
                                                    />
                                                </div>
                                            </Slide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                        </div>
                        {footer}
                        {applyBar}
                    </div>
                </div>
                {applyButton}
                {dialogs}
            </div>
        );
    } else {
        return (
            <div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: `${contentMaxWidth}px 0 1`, margin: '0 auto' }}>
                        <div style={{ position: 'relative' }}>
                            <img className={'background'} src="/images/introduction/background_1.jpg" alt="background_1.jpg" />
                            <div
                                className={'digital'}
                                style={{
                                    position: 'absolute',
                                    top: '20.43%',
                                    left: '39.1%',
                                    width: '20.6%',
                                    height: 9 * contentWidth,
                                    fontSize: 5 * contentWidth,
                                }}
                            >
                                {premiumClass.map((item, key) => (
                                    <span key={key}>{item}</span>
                                ))}
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <img className={'background'} src="/images/introduction/background_2.jpg" alt="background_2.jpg" />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '20.3%',
                                    left: '25%',
                                    width: '50%',
                                }}
                            >
                                <iframe
                                    style={{
                                        boxSizing: 'border-box',
                                        width: '100%',
                                        height: 28 * contentWidth,
                                        border: `${0.5 * contentWidth}px solid yellow`,
                                    }}
                                    title="gJ0JsstWvqc"
                                    src={`${studyVideos[selectedStudyVideo].url}?autoplay=1;rel=0`}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                                <h2
                                    style={{
                                        margin: '1% 0 5% 0',
                                        color: '#fff',
                                        fontSize: contentWidth * 1.8,
                                    }}
                                >
                                    {studyVideos[selectedStudyVideo].title}
                                </h2>
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        height: 10 * contentWidth,
                                        justifyContent: 'space-around',
                                    }}
                                >
                                    {studyVideos.map((video, index) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    cursor: 'pointer',
                                                    width: '24%',
                                                }}
                                                onClick={() => setSelectedStudyVideo(index)}
                                            >
                                                <img
                                                    style={{
                                                        display: 'block',
                                                        width: '100%',
                                                        height: 8 * contentWidth,
                                                        border: index === selectedStudyVideo ? `${0.2 * contentWidth}px solid yellow` : '',
                                                    }}
                                                    alt={String(index)}
                                                    src={video.thumbnail}
                                                />
                                                <span
                                                    style={{
                                                        display: 'inline-block',
                                                        padding: '4%',
                                                        color: '#fff',
                                                        fontSize: 1 * contentWidth,
                                                    }}
                                                >
                                                    {video.subtitle}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div style={{ position: 'relative' }}>
                            <img className={'background'} src="/images/introduction/background_3.jpg" alt="background_3.jpg" />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '65.5%',
                                    left: '25%',
                                    width: '50%',
                                }}
                            >
                                <iframe
                                    style={{
                                        boxSizing: 'border-box',
                                        width: '100%',
                                        height: 28 * contentWidth,
                                        border: `${0.5 * contentWidth}px solid rgb(146,99,246)`,
                                    }}
                                    title="gJ0JsstWvqc"
                                    src={`${reviewVideos[selectedReviewVideo].url}?autoplay=1;rel=0`}
                                    frameBorder="0"
                                    allowFullScreen
                                />
                                <h2
                                    style={{
                                        margin: '1% 0 5% 0',
                                        fontSize: contentWidth * 1.8,
                                    }}
                                >
                                    {reviewVideos[selectedReviewVideo].title}
                                </h2>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 11 * contentWidth,
                                    }}
                                >
                                    <Swiper
                                        style={{ width: '100%' }}
                                        pagination={false}
                                        prevButton={swiperPrevButton(0.03 * contentMaxWidth, 0.03 * contentMaxWidth, 'rgb(146,99,246)')}
                                        nextButton={swiperNextButton(0.03 * contentMaxWidth, 0.03 * contentMaxWidth, 'rgb(146,99,246)')}
                                    >
                                        {[1, 2].map((video, key) => {
                                            return (
                                                <Slide key={key}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            width: '100%',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-around',
                                                                width: '80%',
                                                            }}
                                                        >
                                                            {[1, 2, 3].map((num, _key) => {
                                                                const index = key * 3 + _key;
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            width: '32%',
                                                                        }}
                                                                        onClick={() => setSelectedReviewVideo(index)}
                                                                    >
                                                                        <img
                                                                            style={{
                                                                                width: '100%',
                                                                                height: 10 * contentWidth,
                                                                                cursor: 'pointer',
                                                                                border:
                                                                                    index === selectedReviewVideo
                                                                                        ? `${0.2 * contentWidth}px solid rgb(146,99,246)`
                                                                                        : '',
                                                                            }}
                                                                            alt={String(index)}
                                                                            src={reviewVideos[index].thumbnail}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                display: 'inline-block',
                                                                                padding: '4%',
                                                                                fontSize: contentWidth * 1.2,
                                                                            }}
                                                                        >
                                                                            {reviewVideos[index].subtitle}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </Slide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '84.5%',
                                    width: '100%',
                                    zIndex: 1,
                                    justifyContent: 'center',
                                }}
                            >
                                <Swiper
                                    style={{ width: '45%' }}
                                    pagination={false}
                                    prevButton={swiperPrevButton(0.05 * contentMaxWidth, 0.05 * contentMaxWidth, 'yellow')}
                                    nextButton={swiperNextButton(0.05 * contentMaxWidth, 0.05 * contentMaxWidth, 'yellow')}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((num, key) => {
                                        return (
                                            <Slide key={key}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <img
                                                        style={{
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            width: '70%',
                                                            height: 48 * contentWidth,
                                                            border: `${0.5 * contentWidth}px solid yellow`,
                                                        }}
                                                        alt={String(key)}
                                                        src={`/images/introduction/review${num}.png`}
                                                    />
                                                </div>
                                            </Slide>
                                        );
                                    })}
                                </Swiper>
                            </div>
                        </div>
                        {footer}
                        {applyBar}
                    </div>
                </div>
                {dialogs}
                {applyButton}
            </div>
        );
    }
}

export default Introduction;
