import React, { useContext, useMemo, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    styled as muiStyled,
    TextField,
    Tooltip,
    withStyles,
} from '@material-ui/core';
import { ProductSelectMenu } from './ProductSelectMenu';
import { changeCurrencyUnit, getDiscountPercent, getPriceFromProduct, getTotalMonthlyBillingPrice, getTotalPrice } from '../../utils/currency';
import { PurchaseDirectInfoContextStore } from '../../contexts/purchase-direct.context';
import { googleAnalyticsEvent } from '../../utils/google-analytics';
import { ajax } from '../../utils/jQuery';
import { GRAPHQL_URL, ProductBillingType } from '../../constants/constants';
import { DatePicker } from '@material-ui/pickers';
import styled, { css } from 'styled-components';
import { head, isEmpty, isNil } from '@fxts/core';
import { Clear, InfoOutlined } from '@material-ui/icons';
import { validateFirstDateOfClass, validateMonthlyBillingDay } from '../../utils/validate';
import { getNextBillingDate } from '../../utils/time';

export const basicFontStyles = css`
    font-family: Pretendard;
    font-style: normal;
    font-size: 1rem;
    line-height: 140%;
    letter-spacing: -0.03rem;
`;

const SectionWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`;

const SectionInlineWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 1rem;
`;

const SectionTitle = styled.div`
    ${basicFontStyles};
    font-size: 1rem;
    flex-shrink: 0;
    font-weight: 600;
    color: #222222;
    display: flex;
    align-items: center;
`;

const OriginalPriceSection = styled.div`
    ${basicFontStyles};
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.75rem;
    color: #838383;
    font-size: 1.125rem;
    font-weight: 500;
`;

const CancelFontSpan = styled.span`
    text-decoration: line-through;
`;

const BasicPriceSection = styled.div`
    ${basicFontStyles};
    padding: 1rem 0.75rem;
    border-radius: 0.375rem;
    background-color: #f9f9f9;
    color: #222222;
    font-size: 1.125rem;
    font-weight: 700;
`;

const PriceDescription = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`;

const TotalPriceDescription = styled.div`
    border-top: 1px solid grey;
    padding-top: 0.5rem;
    margin-top: 0.5rem;
`;

const CheckBoxDescription = styled.span`
    ${basicFontStyles};
    font-size: 0.85rem;
`;

const NameBox = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 0;
`;

const PriceBox = styled.span`
    flex-grow: 1;
    flex-shrink: 0;
    text-align: end;
`;

const VerifyPhoneNumberButton = muiStyled(Button)({
    boxSizing: 'border-box',
    backgroundColor: '#999999',
    minWidth: 'fit-content',
    padding: '0.5rem',
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontSize: '0.875rem',
    lineHeight: '100%',
    letterSpacing: '-0.03em',
});

const PayMethodSection = muiStyled(RadioGroup)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
});

const PurchaseButton = muiStyled(Button)({
    borderRadius: '0.5rem',
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontSize: '1.25rem',
    fontWeight: 700,
});

const CustomTooltip = withStyles({
    tooltip: {
        color: '#000000',
        backgroundColor: '#86FFA5',
        minWidth: 'max-content',
        padding: '0.5rem',
        fontFamily: 'Pretendard',
        fontStyle: 'normal',
        fontSize: '0.75rem',
        fontWeight: 'normal',
    },
})(Tooltip);

export const PurchaseModule = (props) => {
    const { purchase } = props;
    const {
        selectedProduct,
        name,
        phoneNumber,
        authCode,
        isSentVerifiedCode,
        verifyAuthCodeLoading,
        setName,
        setPhoneNumber,
        setAuthCode,
        setPayMethod,
        setIsSentVerifiedCode,
        setVerifyAuthCodeLoading,
        firstDateOfClass,
        setFirstDateOfClass,
        monthlyBillingDay,
        firstDateOfClassDisabled,
        monthlyBillingDayDisabled,
        setMonthlyBillingDay,
        purchaseConfigure,
    } = useContext(PurchaseDirectInfoContextStore);
    const { isMultiplePayment } = purchaseConfigure;
    const [noticeChecked, setNoticeChecked] = useState(false);

    const onChangeName = (event) => {
        const { value } = event.target;
        setName(value);
        googleAnalyticsEvent('typing', 'direct_name', value);
    };

    const onChangePhoneNumber = (event) => {
        const { value } = event.target;
        setPhoneNumber(value);
        googleAnalyticsEvent('typing', 'direct_name', value);
    };

    const onChangeAuthCode = (event) => {
        setAuthCode(event.target.value);
    };

    const onChangePayMethod = (event, value) => {
        setPayMethod(value);
    };

    const handleDateChange = (date: Date | null) => {
        setFirstDateOfClass(date);
    };

    const onChangeMonthlyBillingDay = (event) => {
        setMonthlyBillingDay(event.target.value);
    };

    const handleNoticeCheck = (event, value) => {
        setNoticeChecked(value);
    };

    const handleClickVerifyCode = () => {
        if (!isSentVerifiedCode) googleAnalyticsEvent('click', 'button', 'direct_send_verify_code');
        if (isSentVerifiedCode) googleAnalyticsEvent('click', 'button', 'direct_re_send_verify_code');
        setVerifyAuthCodeLoading(true);
        const body = {
            operationName: 'SendAuthVerifySMS',
            variables: { input: { authenticationType: 'SINGUP', phoneNumber } },
            query: `mutation SendAuthVerifySMS($input: SendAuthVerifySMSInput!) {\tsendAuthVerifySMS(input: $input) {\t\tresult\t\t__typename\t}}`,
        };
        ajax({
            type: 'POST',
            url: GRAPHQL_URL,
            data: JSON.stringify(body),
            success: function () {
                setIsSentVerifiedCode(true);
                setVerifyAuthCodeLoading(false);
            },
            contentType: 'application/json',
            dataType: 'json',
        });
    };

    const isPurchaseValueValid = useMemo(() => {
        if (isMultiplePayment) {
            if (!validateMonthlyBillingDay(monthlyBillingDay)) return false;
            if (!validateFirstDateOfClass(firstDateOfClass)) return false;
        }

        if (isEmpty(name)) return false;
        if (isEmpty(phoneNumber)) return false;
        if (isEmpty(authCode)) return false;
        if (!noticeChecked) return false;
        return true;
    }, [monthlyBillingDay, firstDateOfClass, name, phoneNumber, authCode, noticeChecked]);

    return (
        <>
            <SectionWrapper>
                <SectionWrapper>
                    <SectionTitle>기간 및 프로그램 선택</SectionTitle>
                    <ProductSelectMenu />
                </SectionWrapper>
                {selectedProduct?.billingType === ProductBillingType.MONTHLY_BILLING && (
                    <>
                        <SectionInlineWrapper>
                            <SectionTitle>
                                <span>개강일</span>
                                <FirstDayTooltip />
                            </SectionTitle>
                            <DatePicker
                                allowKeyboardControl={false}
                                disableToolbar={true}
                                disabled={firstDateOfClassDisabled}
                                inputVariant="standard"
                                format="yyyy-MM-dd"
                                size="small"
                                InputProps={{ inputProps: { style: { width: '10ch' } } }}
                                value={firstDateOfClass}
                                onChange={handleDateChange}
                                invalidDateMessage={'올바른 형식의 날짜를 입력해주세요.'}
                                maxDateMessage={'선택할 수 있는 최대 날짜를 초과했습니다.'}
                                minDateMessage={'오늘 이전의 날짜를 선택할 수 없습니다.'}
                            />
                        </SectionInlineWrapper>
                        {!isNil(firstDateOfClass) && !isNil(monthlyBillingDay) && (
                            <SectionInlineWrapper>
                                <SectionTitle>
                                    <span>다음 정기 결제일</span>
                                    <MonthlyBillingTooltip />
                                </SectionTitle>
                                <FormControl size={'small'}>{getNextBillingDate(firstDateOfClass, monthlyBillingDay).toFormat('yyyy-MM-dd')}</FormControl>
                            </SectionInlineWrapper>
                        )}
                    </>
                )}
                <PriceSection />
            </SectionWrapper>
            <SectionWrapper>
                <TextField
                    variant={'outlined'}
                    size={'small'}
                    label={'학부모 이름'}
                    placeholder={'학부모 이름을 입력해주세요'}
                    name="name"
                    fullWidth={true}
                    onChange={onChangeName}
                    // error={!/^[ㄱ-ㅎㅏ-ㅣ가-힣a-z0-9_-].{1,10}$/.test(name)}
                    value={name}
                />
                <TextField
                    variant={'outlined'}
                    size={'small'}
                    label={'학부모 연락처'}
                    placeholder={'학부모 연락처를 입력해주세요'}
                    name="phoneNumber"
                    type="tel"
                    fullWidth={true}
                    onChange={onChangePhoneNumber}
                    // error={!/^(?=.*[0-9]).{10,11}$/.test(phoneNumber)}
                    value={phoneNumber}
                    InputProps={{
                        endAdornment: (
                            <VerifyPhoneNumberButton
                                style={{ color: '#ffffff' }}
                                variant="outlined"
                                disabled={phoneNumber.length > 11 || phoneNumber.length < 10 || verifyAuthCodeLoading}
                                onClick={handleClickVerifyCode}
                            >
                                {isSentVerifiedCode ? '재전송' : '인증번호 전송'}
                            </VerifyPhoneNumberButton>
                        ),
                    }}
                />
                {isSentVerifiedCode && (
                    <TextField
                        variant={'outlined'}
                        size={'small'}
                        label="인증번호"
                        name="phoneVerifyCode"
                        type="number"
                        fullWidth={true}
                        value={authCode}
                        onChange={onChangeAuthCode}
                        disabled={verifyAuthCodeLoading}
                    />
                )}
                {selectedProduct?.billingType === ProductBillingType.NORMAL && (
                    <div>
                        <PayMethodSection onChange={onChangePayMethod} defaultValue={'card'}>
                            <FormControlLabel value="card" control={<Radio color={'primary'} />} label="신용카드" />
                            <FormControlLabel value="vbank" control={<Radio color={'primary'} />} label="가상계좌" />
                            <FormControlLabel value="kakaopay" control={<Radio color={'primary'} />} label="카카오페이" />
                        </PayMethodSection>
                    </div>
                )}
                <div>
                    <Checkbox size={'small'} checked={noticeChecked} onChange={handleNoticeCheck} />
                    <CheckBoxDescription>유의사항을 모두 확인하였으며, 이에 동의합니다.</CheckBoxDescription>
                </div>
                <PurchaseButton variant={'contained'} fullWidth={true} color={'secondary'} onClick={purchase} disabled={!isPurchaseValueValid}>
                    결제하기
                </PurchaseButton>
            </SectionWrapper>
        </>
    );
};

const PriceSection = () => {
    const { purchaseConfigure } = useContext(PurchaseDirectInfoContextStore);
    const { isMultiplePayment } = purchaseConfigure;

    if (isMultiplePayment) {
        return <MultipleProductPriceSection />;
    } else {
        return <SingleProductPriceSection />;
    }
};

const MultipleProductPriceSection = () => {
    const { productListOnCart, firstDateOfClass, monthlyBillingDay } = useContext(PurchaseDirectInfoContextStore);

    if (isEmpty(productListOnCart)) return null;

    return (
        <BasicPriceSection>
            <div style={{ maxHeight: '5rem', overflow: 'auto' }}>
                {productListOnCart.map((product, key) => {
                    const price = getPriceFromProduct(product, firstDateOfClass, monthlyBillingDay);

                    return (
                        <PriceDescription key={key}>
                            <NameBox>{product.name}</NameBox>
                            <SubtractProductButton productId={product.id} />
                            <PriceBox style={{ flexShrink: 0 }}>{changeCurrencyUnit(price)}원</PriceBox>
                        </PriceDescription>
                    );
                })}
            </div>
            <TotalPriceDescription>
                <PriceDescription>
                    <NameBox>이번 결제 금액</NameBox>
                    <PriceBox>{changeCurrencyUnit(getTotalPrice(productListOnCart, firstDateOfClass, monthlyBillingDay))}원</PriceBox>
                </PriceDescription>
                <PriceDescription>
                    <NameBox>매달 결제 금액</NameBox>
                    <PriceBox>{changeCurrencyUnit(getTotalMonthlyBillingPrice(productListOnCart))}원</PriceBox>
                </PriceDescription>
            </TotalPriceDescription>
        </BasicPriceSection>
    );
};

const SingleProductPriceSection = () => {
    const { productListOnCart, firstDateOfClass, monthlyBillingDay } = useContext(PurchaseDirectInfoContextStore);
    const product: any = head(productListOnCart);

    const originalPrice = product?.originalPrice ?? 0;
    const price = getPriceFromProduct(product, firstDateOfClass, monthlyBillingDay);
    const isDiscounted = originalPrice > price;

    return (
        <>
            {isDiscounted && (
                <OriginalPriceSection>
                    <span>기본 수강료</span>
                    <CancelFontSpan>{changeCurrencyUnit(originalPrice)}원</CancelFontSpan>
                </OriginalPriceSection>
            )}
            <BasicPriceSection>
                {isDiscounted ? (
                    <PriceDescription>
                        <span>{getDiscountPercent(price, originalPrice)}% 할인 적용가</span>
                        <span>{changeCurrencyUnit(price)}원</span>
                    </PriceDescription>
                ) : (
                    <>
                        <PriceDescription>
                            <span>기본 수강료</span>
                            <span>{changeCurrencyUnit(price)}원</span>
                        </PriceDescription>
                    </>
                )}
                {product?.refundDate > 0 && (
                    <PriceDescription>
                        <span>{product?.refundDate}일 이내 100% 환불 보장기간</span>
                        <span>{product?.refundDate ? '포함' : '미포함'}</span>
                    </PriceDescription>
                )}
            </BasicPriceSection>
        </>
    );
};

const MonthlyBillingTooltip = () => {
    return (
        <CustomInformationTooltip>
            <div>정기 결제일이 뭔가요?</div>
            <div>· 매달 수업료가 결제되는 날이에요.</div>
            <div>
                · 정기 결제일에는 <b>그 다음달</b>의 수업료가 결제돼요.
            </div>
        </CustomInformationTooltip>
    );
};

const FirstDayTooltip = () => {
    return (
        <CustomInformationTooltip>
            <div>개강일이 뭔가요?</div>
            <div>· 수업을 처음 시작하는 날이에요.</div>
            <div>· 개강일을 기준으로 첫 수업료가 결정돼요.</div>
        </CustomInformationTooltip>
    );
};

const CustomInformationTooltip = (props) => {
    const { children } = props;
    const [open, setOpen] = useState<boolean>(false);
    const openTooltip = () => {
        setOpen(true);
    };
    const closeTooltip = () => {
        setOpen(false);
    };

    return (
        <CustomTooltip open={open} onClick={openTooltip} onClose={closeTooltip} title={children} arrow leaveTouchDelay={10000}>
            <IconButton style={{ width: '1.125rem', height: '1.125rem' }}>
                <InfoOutlined style={{ width: '1.125rem', height: '1.125rem' }} />
            </IconButton>
        </CustomTooltip>
    );
};

const SubtractProductButton = (props) => {
    const { productId } = props;
    const { productListOnCart, setProductListOnCart } = useContext(PurchaseDirectInfoContextStore);

    const subtractProduct = () => {
        const newProductList = productListOnCart.filter((item) => item.id !== productId);
        setProductListOnCart(newProductList);
    };

    return (
        <IconButton onClick={subtractProduct} style={{ width: '1rem', height: '0.75rem' }}>
            <Clear style={{ width: '0.75rem', height: '0.75rem' }} />
        </IconButton>
    );
};
