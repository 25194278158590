import React from 'react';
import styled from 'styled-components';
import { changeCurrencyUnit, getDiscountedPrice, getDiscountPercent } from '../../utils/currency';
import { isEmpty } from '@fxts/core';
import { ProductSet } from '../../constants/product-set';

const Wrapper = styled.div`
    display: inline-block;
    position: relative;
    border: 4px solid #ffffff;
    background-image: url('/images/payment/product_card.jpg');
    background-size: contain;
    box-shadow:
        rgba(0, 0, 0, 0.12) 0px 1px 8px,
        rgba(0, 0, 0, 0.12) 0px 1px 6px;
    cursor: pointer;
    width: ${(props) => (props.isMobile ? '90%' : '48%')};
    margin: ${(props) => (props.isMobile ? '0% 5% 5% 5%' : '1%')};
    border-radius: ${(props) => (props.isMobile ? '5px' : '10px')};
`;

const ContentWrapper = styled.div`
    width: 100%;
    padding: 5% 10%;
`;

const CardContentComponent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: ${(props) => props.cardWidth * 10.5}px;
`;

const Title = styled.span`
    font-size: ${(props) => props.cardWidth * 1.5}px;
    font-weight: bold;
`;

const Description = styled.div`
    font-size: ${(props) => props.cardWidth * 1.1}px;
    color: grey;
`;

const ProductListComponent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

const ProductItem = styled.div`
    font-size: ${(props) => props.cardWidth * 1.1}px;
    color: grey;
`;

const DiscountInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row-reverse;
    margin-top: auto;
    font-size: ${(props) => props.cardWidth * 1.3}px;
    color: #aaa;
    font-weight: bold;
`;

const PriceWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: row-reverse;
`;

const CurrentPrice = styled.div`
    font-size: ${(props) => props.cardWidth * 1.8}px;
    font-weight: bold;
    color: hotpink;
`;

const OriginalPrice = styled.div`
    font-size: ${(props) => props.cardWidth * 1.3}px;
    flex-grow: 1;
    color: #aaa;
    position: relative;

    img {
        position: absolute;
        bottom: ${(props) => props.cardWidth * 0.7}px;
        width: 100%;
    }
`;

interface ProductSetCardProps {
    isMobile: boolean;
    productSet: ProductSet;
    contentWidth: number;
    openPaymentDialog: (productSet: ProductSet) => void;
}

export const ProductSetCard = (props: ProductSetCardProps) => {
    const { isMobile, productSet, contentWidth, openPaymentDialog } = props;
    const { name, description, price, originalPrice } = productSet;

    const cardWidth = isMobile ? contentWidth * 3.1 : contentWidth;

    return (
        <Wrapper isMobile={isMobile}>
            <div onClick={openPaymentDialog.bind(null, productSet)}>
                <ContentWrapper>
                    <CardContentComponent cardWidth={cardWidth}>
                        <div>
                            <Title cardWidth={cardWidth}>{name}</Title>
                            {description && <Description cardWidth={cardWidth}>{description}</Description>}
                        </div>
                        {!isEmpty(productSet.products) && (
                            <ProductListComponent>
                                {productSet.products.map((product, idx) => (
                                    <ProductItem key={idx} cardWidth={cardWidth}>
                                        {product.name} * {product.quantity}
                                    </ProductItem>
                                ))}
                            </ProductListComponent>
                        )}
                        {getDiscountPercent(price, originalPrice) > 0 && (
                            <DiscountInfo cardWidth={cardWidth}>
                                {changeCurrencyUnit(getDiscountedPrice(price, originalPrice))}원(
                                {getDiscountPercent(price, originalPrice)}%) 할인
                            </DiscountInfo>
                        )}
                        <PriceWrapper>
                            <CurrentPrice cardWidth={cardWidth}>{changeCurrencyUnit(price)}원</CurrentPrice>
                            {getDiscountedPrice(price, originalPrice) > 0 && (
                                <OriginalPrice cardWidth={cardWidth}>
                                    <img alt="arrow" src="/images/payment/arrow.png" />
                                    {changeCurrencyUnit(originalPrice)}원
                                </OriginalPrice>
                            )}
                        </PriceWrapper>
                    </CardContentComponent>
                </ContentWrapper>
            </div>
        </Wrapper>
    );
};
