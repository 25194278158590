import { DEVICE, MAX_DESKTOP_CONTENT_WIDTH, MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH, MIDDLE_DESKTOP_WIDTH, MIN_DESKTOP_CONTENT_WIDTH } from '../constants/constants';

export const isMobileByScreenWidth = (screenWidth) => screenWidth <= MAX_MOBILE_WIDTH;
export const isTabletByScreenWidth = (screenWidth) => screenWidth > MAX_MOBILE_WIDTH && screenWidth <= MIDDLE_DESKTOP_WIDTH;
export const isDesktopByScreenWidth = (screenWidth) => screenWidth > MIDDLE_DESKTOP_WIDTH;
const isDesktopMinWidth = (screenWidth) => screenWidth <= MIDDLE_DESKTOP_WIDTH;
const isDesktopMaxWidth = (screenWidth) => screenWidth > MIDDLE_DESKTOP_WIDTH;

export const getDeviceByScreenWidth = (screenWidth) => {
    if (isMobileByScreenWidth(screenWidth)) return DEVICE.MOBILE;
    if (isTabletByScreenWidth(screenWidth)) return DEVICE.TABLET;
    if (isDesktopByScreenWidth(screenWidth)) return DEVICE.DESKTOP;
};

export const getDesktopContentWidth = (screenWidth) => {
    if (isDesktopMinWidth(screenWidth)) return MIN_DESKTOP_CONTENT_WIDTH;
    if (isDesktopMaxWidth(screenWidth)) return MAX_DESKTOP_CONTENT_WIDTH;
};
