import React from 'react';
import { Footer } from '../../components/common/Footer';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '../../constants/constants';
import { PaymentSectionList } from '../../components/shop/PaymentSectionList';

import { TotalShopSection } from '../../constants/payment-total-shop-page';

const Wrapper = styled.div`
    font-family: Pretendard;
`;

const DesktopPromotionImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 0rem;
    background: #172c28;
`;

const DesktopImage = styled.img`
    display: block;
    width: ${MAX_TABLET_WIDTH}px;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        width: 100%;
        padding: 1rem;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 3rem;
    background: linear-gradient(96.2deg, #293534 32.01%, rgba(41, 53, 52, 0) 105.93%), linear-gradient(311.67deg, #10d46c 1.13%, #0da8ff 104.35%),
        linear-gradient(318.19deg, #10d46c 17.91%, #00a64e 91.44%);
    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        padding: 1rem;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: ${MAX_TABLET_WIDTH}px;
`;

const HeaderSection = styled.div`
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    align-items: center;
`;

const HeaderDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    justify-content: flex-start;
    color: #ffffff;
`;

const Font1 = styled.div`
    font-size: 3rem;
    font-weight: 600;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 2rem;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1.5rem;
    }
`;

const Font2 = styled.div`
    font-size: 2rem;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 1.3rem;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

const Font3 = styled.div`
    font-size: 1.5rem;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 1rem;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 0.75rem;
    }
`;

const HeaderIcon = styled.img`
    display: block;
    width: 15rem;
    height: 16rem;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        width: 10rem;
        height: 11rem;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 7.5rem;
        height: 8rem;
    }
`;

const PreparingCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 2rem;
    width: 100%;
    min-width: 30rem;
    border-radius: 0.5rem;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        min-width: 20rem;
    }

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        min-width: 10rem;
    }
`;

const PreparingCardDescription = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const PlusIcon = styled.div`
    color: #ffffff;
    font-size: 4rem;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 2rem;
    }
`;

interface PaymentTotalShopProps {
    sections: TotalShopSection[];
    isPromotion?: boolean;
}

export const PaymentTotalShop = ({ sections, isPromotion }: PaymentTotalShopProps) => {
    return (
        <Wrapper>
            {isPromotion && (
                <DesktopPromotionImageWrapper>
                    <DesktopImage src="/images/payment-direct/main/desktop/0.png" alt="promotion-header" />
                </DesktopPromotionImageWrapper>
            )}
            <HeaderWrapper>
                <Header>
                    <HeaderSection>
                        <HeaderDescription>
                            <Font2>강의력과 개인관리를 동시에 잡은</Font2>
                            <Font1>
                                <div>전문 선생님과의</div>
                                <div>주 4회 1:1 맞춤PT</div>
                            </Font1>
                            <Font3>
                                <div>오로지 한 명의 학생을 위한 맞춤형 커리큘럼으로</div>
                                <div>합리적인 가격에 집에서 1:1 관리를 받아보세요</div>
                            </Font3>
                        </HeaderDescription>
                        <HeaderIcon src="/images/payment-direct/main/shop-hero.png" alt="header-icon" />
                    </HeaderSection>
                    <HeaderSection>
                        <PreparingCard>
                            <Font1>내신대비</Font1>
                            <PreparingCardDescription>
                                <Font3>전국 교과서 & 부교재 내신 완벽대비</Font3>
                                <Font3>변형문제 + 서술형 완벽대비</Font3>
                            </PreparingCardDescription>
                        </PreparingCard>
                        <PlusIcon>+</PlusIcon>
                        <PreparingCard>
                            <Font1>수능대비</Font1>
                            <PreparingCardDescription>
                                <Font3>노베이스 ~ 1등급까지 수준별 커리큘럼</Font3>
                                <Font3>최대 250개 강의 + 개별 커리큘럼</Font3>
                            </PreparingCardDescription>
                        </PreparingCard>
                    </HeaderSection>
                </Header>
            </HeaderWrapper>
            <PaymentSectionList sections={sections} />
            <Footer />
        </Wrapper>
    );
};
