import { getCookie } from './cookie';
import { REST_URL } from '../constants/constants';

type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

export async function customFetch(method: FetchMethod, url = '', data?) {
    const response = await fetch(REST_URL + url, {
        method,
        // mode: 'no-cors',
        headers: {
            'Content-Type': 'application/json',
            Authorization: getCookie('loginToken') ?? '',
        },
        body: data ? JSON.stringify(data) : undefined,
    });
    const result = await response.json();
    return result;
}
