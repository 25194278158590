import React, { useEffect, useState } from 'react';
import { Footer } from '../../components/common/Footer';
import { purchaseWithIamport } from '../../utils/purchase';
import { GRAPHQL_URL, MAX_DESKTOP_WIDTH, REST_URL } from '../../constants/constants';
import { PaymentDialog } from '../../components/payment/PaymentDialog';
import { ProductList } from '../../components/payment/ProductList';
import { regExp } from '../../utils/reg-exp';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { ajax } from '../../utils/jQuery';
import { PayMethod } from '../../constants/payment';
import { ProductRulesAccordion } from '../../components/purchase/ProductRulesAccordion';

export interface PaymentState {
    selectedProduct?: any;
    products: any[];
    isDialogOpen: boolean;
    name: string;
    phoneNumber: string;
    payMethod: PayMethod;
    contentWidth: number;
    authCode: string;
    isSentVerifiedCode: boolean;
}

function Payment(props) {
    const initialStates: PaymentState = {
        selectedProduct: undefined,
        products: [],
        isDialogOpen: false,
        name: '',
        phoneNumber: '',
        payMethod: 'card',
        contentWidth: (window.innerWidth < MAX_DESKTOP_WIDTH ? window.innerWidth : MAX_DESKTOP_WIDTH) / 100,
        authCode: '',
        isSentVerifiedCode: false,
    };

    const { isMobile, paymentType, token, direct, extension, recurring, queryParams, options } = props;

    const [selectedProduct, setSelectedProduct] = useState(initialStates.selectedProduct);
    const [products, setProducts] = useState(initialStates.products);
    const [isDialogOpen, setDialogOpen] = useState(initialStates.isDialogOpen);
    const [name, setName] = useState(initialStates.name);
    const [phoneNumber, setPhoneNumber] = useState(initialStates.phoneNumber);
    const [payMethod, setPayMethod] = useState(initialStates.payMethod);
    const [contentWidth, setContentWidth] = useState(initialStates.contentWidth);
    const [authCode, setAuthCode] = useState(initialStates.authCode);
    const [isSentVerifiedCode, setIsSentVerifiedCode] = useState(initialStates.isSentVerifiedCode);
    const { startDateOfClass, endDateOfClass } = options;

    const openPaymentDialog = (product) => {
        googleAnalyticsEvent('click', 'button', 'product_card');
        googleAnalyticsEvent('click', 'button', `product_card_${product.price}`);
        setSelectedProduct(product);
        setDialogOpen(true);
    };

    const closePaymentDialog = () => {
        setSelectedProduct(initialStates.selectedProduct);
        setName(initialStates.name);
        setPhoneNumber(initialStates.phoneNumber);
        setPayMethod(initialStates.payMethod);
        setDialogOpen(initialStates.isDialogOpen);
        setAuthCode(initialStates.authCode);
        setIsSentVerifiedCode(initialStates.isSentVerifiedCode);
    };

    const onKeyDownOnPaymentDialog = (event) => {
        if (event.key === 'Escape') closePaymentDialog();
        if (event.key === 'Enter') purchase();
    };

    const onChangeName = (event) => {
        setName(event.target.value);
    };

    const onChangePhoneNumber = (event) => {
        setPhoneNumber(event.target.value);
    };

    const onChangeAuthCode = (event) => {
        setAuthCode(event.target.value);
    };

    const onChangePayMethod = (event, value) => {
        setPayMethod(value);
    };

    const purchase = async () => {
        if (!extension) {
            const isVerified = await veryfyAuthCode();
            if (!isVerified) return alert('인증번호가 일치하지 않습니다.');
        }
        if (!regExp.name.test(name)) return alert('이름을 다시 확인해주세요.');
        if (!regExp.phoneNumber.test(phoneNumber)) return alert('연락처를 다시 확인해주세요.');

        closePaymentDialog();
        purchaseWithIamport({
            products: [selectedProduct],
            amount: selectedProduct.price,
            payMethod,
            buyerName: name,
            buyerTel: phoneNumber,
            token,
            direct,
            extension,
            queryParams,
            startDateOfClass,
            endDateOfClass,
        });
    };

    const getProducts = (paymentType, recurring) => {
        ajax({
            type: 'GET',
            url: REST_URL + `/products?productType=1&paymentType=${paymentType}&recurringType=${recurring}`,
            success: function (result) {
                setProducts(result.data.products);
            },
            contentType: 'application/json',
            dataType: 'json',
        });
    };

    const veryfyAuthCode = async () => {
        return new Promise((resolve, reject) => {
            const body = {
                operationName: 'VerifyAuthCode',
                variables: { input: { code: authCode, phoneNumber } },
                query: `mutation VerifyAuthCode($input: VerifyAuthCodeInput!) {  verifyAuthCode(input: $input) {    result    __typename  }}`,
            };
            ajax({
                type: 'POST',
                url: GRAPHQL_URL,
                data: JSON.stringify(body),
                success: function (result) {
                    if (result.data.verifyAuthCode !== null) resolve(true);
                    resolve(false);
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        });
    };

    const getTitleImageSource = (isMobile) => {
        if (isMobile) {
            return '/images/payment/m_background_1_mega.png';
        } else {
            return '/images/payment/background_1_mega.png';
        }
    };

    const getTitle2ImageSource = (isMobile) => {
        if (isMobile) {
            return '/images/payment/m_background_2_mega_2.png';
        } else {
            return '/images/payment/background_2_mega_2.png';
        }
    };

    const getInvestmentImageSource = (isMobile) => {
        if (isMobile) {
            return '/images/payment/m_background_4.jpg';
        } else {
            return '/images/payment/background_4.jpg';
        }
    };

    const titleImageSource = getTitleImageSource(isMobile);
    const title2ImageSource = getTitle2ImageSource(isMobile);
    const investmentImageSource = getInvestmentImageSource(isMobile);
    const imageStyle = { display: 'block', width: '100%' };

    useEffect(() => {
        const handleContentWidth = () => setContentWidth((window.innerWidth < MAX_DESKTOP_WIDTH ? window.innerWidth : MAX_DESKTOP_WIDTH) / 100);
        window.addEventListener('resize', handleContentWidth);
        return () => window.removeEventListener('resize', handleContentWidth);
    }, []);

    useEffect(() => {
        getProducts(paymentType, recurring);
    }, [paymentType, recurring]);

    useEffect(() => {
        googleAnalyticsConfig('결제 페이지', '/payment');
    });

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: `${MAX_DESKTOP_WIDTH}px 0 1`, margin: '0 auto' }}>
                <img style={imageStyle} alt="background_1.jpg" src={titleImageSource} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: isMobile ? '1rem' : '2rem',
                        backgroundColor: '#E7E7E7',
                        padding: isMobile ? '2rem 0rem' : '4rem 0rem',
                    }}
                >
                    <img
                        style={{ display: 'block', width: isMobile ? '90%' : '56%' }}
                        alt="mega_description.jpg"
                        src={'/images/payment/mega_description.png'}
                    />
                    <iframe
                        style={{
                            position: 'relative',
                            display: 'block',
                            width: isMobile ? '90%' : '56%',
                            height: contentWidth * (isMobile ? 50 : 31) + 'px',
                        }}
                        src="https://www.youtube.com/embed/iV80jXEomxo"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                </div>
                <img style={imageStyle} alt="background_2.jpg" src={title2ImageSource} />
                <ProductList
                    isMobile={isMobile}
                    products={products}
                    contentWidth={contentWidth}
                    paymentType={paymentType}
                    options={options}
                    openPaymentDialog={openPaymentDialog}
                />
                <img style={imageStyle} alt="background_4.jpg" src={investmentImageSource} />
                <ProductRulesAccordion />
                <Footer />
                <PaymentDialog
                    product={selectedProduct}
                    extension={extension}
                    phoneNumber={phoneNumber}
                    name={name}
                    isDialogOpen={isDialogOpen}
                    onChangeName={onChangeName}
                    onChangePhoneNumber={onChangePhoneNumber}
                    onChangePayMethod={onChangePayMethod}
                    onKeyDownOnPaymentDialog={onKeyDownOnPaymentDialog}
                    closePaymentDialog={closePaymentDialog}
                    purchase={purchase}
                    authCode={authCode}
                    onChangeAuthCode={onChangeAuthCode}
                    isSentVerifiedCode={isSentVerifiedCode}
                    setIsSentVerifiedCode={setIsSentVerifiedCode}
                />
            </div>
        </div>
    );
}

export default Payment;
