import { Button, SwipeableDrawer } from '@material-ui/core';
import React, { useState } from 'react';
import { PurchaseModule } from './PurchaseModule';

import styled from 'styled-components';

const Wrapper = styled.div`
    position: sticky;
    bottom: 0;
`;

const ButtonWrapper = styled.div`
    width: 100%;
    padding: 1rem;
`;

const PurchaseButton = styled(Button)`
    width: 100%;
    background: #28d05d;
    color: white;
    font-family: Pretendard;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    font-size: 1.5rem;
    padding: 0.5rem;
`;

const PurchaseSectionWrapper = styled.div`
    padding: 1rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const PurchaseModuleDrawer = (props) => {
    const { purchase } = props;
    const [isExpanded, setIsExpanded] = useState(false);

    const expandCard = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Wrapper>
            {isExpanded === false && (
                <ButtonWrapper>
                    <PurchaseButton variant={'contained'} fullWidth={true} onClick={expandCard}>
                        구매하기
                    </PurchaseButton>
                </ButtonWrapper>
            )}
            <div>
                <SwipeableDrawer anchor={'bottom'} open={isExpanded} onClose={expandCard} onOpen={expandCard}>
                    <PurchaseSectionWrapper>
                        <PurchaseModule purchase={purchase} />
                    </PurchaseSectionWrapper>
                </SwipeableDrawer>
            </div>
        </Wrapper>
    );
};
