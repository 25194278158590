import React from 'react';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '../../constants/constants';
import { PurchaseType } from '../../constants/purchase-page';
import styled from 'styled-components';
import Arrow from './Arrow';

const CardWrapper = styled.div`
    width: 100%;
    min-width: 20rem;
    max-width: 30rem;
    padding: 2rem;
    border-radius: 7.5rem;
    background-color: #f5f6f8;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 1rem;
    }

    &:hover {
        background-color: ${(props) => props.color + '80'};
    }
`;

const CardTitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const CardTitle = styled.div`
    font-size: 2rem;
    font-weight: 700;
    min-width: fit-content;
    color: #1a1a1a;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 1.33rem;
    }
`;

const CardDescription = styled.div`
    font-size: 1rem;
    font-weight: 400;
    min-width: fit-content;
    color: #1a1a1a;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 0.66rem;
    }
`;

const MoveIcon = styled.div`
    min-width: 3rem;
    min-height: 3rem;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        min-width: 1.75rem;
        min-height: 1.75rem;
    }
`;

const ArrowIcon = styled(Arrow)`
    width: 26px;
    height: 18px;
    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        width: 20px;
        height: 12px;
    }
`;

export interface PurchaseCardStretchedProps {
    title: string;
    description: string;
    color: string;
    purchaseType: PurchaseType;
}

export const PurchaseCardStretched = (props: PurchaseCardStretchedProps) => {
    const { title, description, color, purchaseType } = props;

    const moveToPurchasePage = () => {
        window.location.href = window.origin + `/purchase?purchase_type=${purchaseType}`;
    };

    return (
        <CardWrapper color={color} onClick={moveToPurchasePage}>
            <CardTitleWrapper>
                <CardTitle>{title}</CardTitle>
                <CardDescription>{description}</CardDescription>
            </CardTitleWrapper>
            <MoveIcon color={color}>
                <ArrowIcon />
            </MoveIcon>
        </CardWrapper>
    );
};
