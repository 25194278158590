import * as React from 'react';

function Advantage2(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16252" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <rect y="0.5" width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16252)">
                <path
                    d="M35.539 23.155H92.8366C94.5812 23.155 96 24.5738 96 26.3184V70.805C96 72.1922 94.8755 73.3063 93.4987 73.3063H36.8421M63.4206 74.1996V87.6832M48.6863 89.4068L63.4206 81.4511L78.1444 89.4068M44.5981 62.513L58.8805 49.3236C59.5531 48.7036 60.5831 48.7036 61.2557 49.3236L65.6591 53.4223C66.3528 54.0634 67.4247 54.0424 68.0868 53.3698L84.4816 36.7122M75.7062 35.6718L85.2488 35.7138C85.4064 35.7138 85.5326 35.8505 85.522 36.0081L84.9861 45.5297M25.7126 33.8957L29.2648 34.789C32.0183 35.4826 34.8348 35.8294 37.6724 35.8294H48.4761C50.6726 35.8294 52.4592 37.6161 52.4592 39.8125C52.4592 42.009 50.6726 43.7956 48.4761 43.7956H30.3052C29.9689 43.7956 29.6957 44.0689 29.6957 44.4052V87.8619C29.6957 89.144 28.1508 90.6889 26.2486 90.6889H23.8944C21.9922 90.6889 20.4473 89.144 20.4473 87.2418M20.4473 87.2418V68.8502M20.4473 87.2418C20.4473 89.144 18.9024 90.6889 17.0002 90.6889H14.6461C12.7439 90.6889 11.199 89.144 11.199 87.2418V58.4984M15.4343 33.8116L7.53119 36.4285C5.42929 37.1221 4 39.0979 4 41.3154V56.5226C4 58.8662 5.90222 60.7684 8.24583 60.7684H8.81334C10.148 60.7684 11.2305 59.686 11.2305 58.3513V44.9832M20.4578 71.5617V66.0022M27.8775 19.3401C27.8775 24.3111 24.5986 30.0072 20.5629 30.0072C16.5273 30.0072 13.2483 24.3216 13.2483 19.3401C13.2483 14.3586 16.5273 11.5 20.5629 11.5C24.5986 11.5 27.8775 14.3691 27.8775 19.3401Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Advantage2;
