import { GOOGLE_ANALYTICS_4_CODE, GOOGLE_ANALYTICS_CODE } from '../constants/constants';

export const googleAnalyticsEvent = (event, category, label) => {
    if (typeof (window as any).gtag !== 'function') return;

    (window as any).gtag('event', event, {
        event_category: category,
        event_label: label,
    });
};

export const googleAnalyticsConversionEvent = (value, currency) => {
    if (typeof (window as any).gtag !== 'function') return;
    (window as any).gtag('event', 'conversion', {
        send_to: GOOGLE_ANALYTICS_CODE,
        value: value,
        currency: currency,
    });
    (window as any).gtag('event', 'conversion', {
        send_to: GOOGLE_ANALYTICS_4_CODE,
        value: value,
        currency: currency,
    });
};

export const googleAnalyticsConfig = (title, path) => {
    if (typeof (window as any).gtag !== 'function') return;
    (window as any).gtag('config', GOOGLE_ANALYTICS_CODE, {
        page_title: title,
        page_path: path,
    });
    (window as any).gtag('config', GOOGLE_ANALYTICS_4_CODE, {
        page_title: title,
        page_path: path,
    });
};
