import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    props: {
        MuiModal: {
            disableScrollLock: true,
        },
    },
    palette: {
        primary: {
            main: '#28D05D',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main: '#000000',
            contrastText: '#FFFFFF',
        },
    },
});
