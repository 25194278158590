import * as React from 'react';

function Arrow(props: any) {
    return (
        <svg width="26" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2 7.8C1.33726 7.8 0.8 8.33726 0.8 9C0.8 9.66274 1.33726 10.2 2 10.2V7.8ZM24.8485 9.84853C25.3172 9.3799 25.3172 8.6201 24.8485 8.15147L17.2118 0.514718C16.7431 0.0460892 15.9833 0.0460892 15.5147 0.514718C15.0461 0.983348 15.0461 1.74315 15.5147 2.21177L22.3029 9L15.5147 15.7882C15.0461 16.2569 15.0461 17.0167 15.5147 17.4853C15.9833 17.9539 16.7431 17.9539 17.2118 17.4853L24.8485 9.84853ZM2 10.2H24V7.8H2V10.2Z"
                fill="white"
            />
        </svg>
    );
}

export default Arrow;
