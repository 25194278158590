import React, { useState } from 'react';
import { Login } from './Login';

import PageManager from './PageManager';
import { getCookie } from '../../utils/cookie';
const Manager = () => {
    const loginToken = getCookie('loginToken');
    const [isLogined, setIsLogined] = useState(loginToken !== undefined);
    if (!isLogined) return <Login setIsLogined={setIsLogined} />;
    return <PageManager />;
};

export default Manager;
