import React, { useEffect, useState } from 'react';
import { Button, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { customFetch } from '../../utils/custom-fetch';
import styled from 'styled-components';

const AppBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    background-color: white;
    padding: 0rem 1rem;
`;

const FuctionWrapper = styled.div`
    display: flex;
    gap: 4px;
    justify-content: right;
`;

const PageManager = () => {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        getPages();
    }, []);

    const getPages = async () => {
        const {
            data: { paymentPages },
        } = await customFetch('GET', '/paymentPages');

        const pages = paymentPages.map((page) => {
            page.url = global.location.origin + '/' + page.restUrl;
            return page;
        });
        setPages(pages);
    };

    const deletePage = async (id) => {
        if (!global.confirm('정말로 페이지를 삭제하시겠습니까?')) return;
        const result = await customFetch('DELETE', `/paymentPages/${id}`);
        if (result.meta.code !== 0) return alert(result.meta.message);
        await getPages();
    };

    const moveToEditPage = async (id) => {
        const pageUrl = `${global.location.origin}/editPage?payment_page_id=${id}`;
        global.location.href = pageUrl;
    };

    const moveToCreatePage = () => {
        const pageUrl = `${global.location.origin}/createPage`;
        global.location.href = pageUrl;
    };

    return (
        <div style={{ backgroundColor: 'whitesmoke' }}>
            <AppBar>
                <Typography variant={'h6'}>결제 페이지 목록</Typography>
                <Button onClick={moveToCreatePage} variant={'contained'} color={'primary'}>
                    생성
                </Button>
            </AppBar>
            <Container maxWidth="lg" style={{ margin: '2rem auto 0rem' }}>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>제목</TableCell>
                                <TableCell>주소</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pages.map(({ id, title, url }, i) => (
                                <TableRow key={id}>
                                    <TableCell>{title}</TableCell>
                                    <TableCell>
                                        <a href={url}>{url}</a>
                                    </TableCell>
                                    <TableCell>
                                        <FuctionWrapper>
                                            <Button onClick={moveToEditPage.bind(null, id)} variant={'contained'} color={'primary'} size={'small'}>
                                                수정
                                            </Button>
                                            <Button onClick={deletePage.bind(null, id)} variant={'contained'} color={'primary'} size={'small'}>
                                                삭제
                                            </Button>
                                        </FuctionWrapper>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </div>
    );
};

export default PageManager;
