import * as React from 'react';

function Strategy2(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16212" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <rect width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16212)">
                <path
                    d="M39.7597 54.547L19.8163 60.972C14.5632 62.6626 11 67.5819 11 73.1318V93.9018C11 95.0596 11.934 96 13.084 96H86.7622C87.9122 96 88.8462 95.0596 88.8462 93.9018V73.1318C88.8462 67.5819 85.2829 62.6626 80.0298 60.972L60.0378 54.5307M60.0864 46.1107V53.7914C60.0864 56.1288 58.7151 58.2705 56.5718 59.1619C54.7955 59.9012 52.4794 60.5698 49.9258 60.5698C47.3721 60.5698 45.0506 59.9012 43.2798 59.1619C41.1364 58.2705 39.7651 56.1288 39.7651 53.7914V46.1053M27.6285 96V79.6493M72.2177 96V79.6493M39.7597 55.9983C42.0434 64.5651 45.4285 73.1318 49.9204 81.6986M60.0864 55.9983C57.8027 64.5651 54.4176 73.1318 49.9258 81.6986V96M69.4642 25.0961C69.4642 38.4735 60.7181 49.3178 49.9204 49.3178C39.1227 49.3178 30.3819 38.4735 30.3819 25.0961C30.3819 11.7188 39.1335 4 49.9258 4C60.7181 4 69.4696 11.7188 69.4696 25.0961H69.4642Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Strategy2;
