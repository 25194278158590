import React from 'react';
import { ExpandMore } from '@material-ui/icons';
import { Button } from '@material-ui/core';

export const TopNaivgator = () => {
    const onClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Button
            onClick={onClick}
            style={{
                width: '360px',
                height: '55px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#FFFFFF',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                borderRadius: '10px',
            }}
        >
            <span
                style={{
                    fontFamily: 'Pretendard',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '20px',
                    lineHeight: '140%',
                    color: '#5E6266',
                }}
            >
                TOP
            </span>
            <ExpandMore style={{ transform: 'rotate(180deg)', color: '#5E6266' }} />
        </Button>
    );
};
