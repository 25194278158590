import React, { useState } from 'react';
import { Button, Dialog, TextField } from '@material-ui/core';
import { changeCurrencyUnit } from '../../utils/currency';
import { ajax } from '../../utils/jQuery';
import { GRAPHQL_URL } from '../../constants/constants';
import { isEmpty } from '@fxts/core';
import styled from 'styled-components';

const CustomDialog = styled(Dialog)`
    & .MuiPaper-root {
        max-width: 380px;
        width: 100%;
    }
`;

const DialogContent = styled.div`
    padding: 24px;
`;

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const Price = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`;

const ProductList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 24px;
`;

const ProductItem = styled.div`
    font-size: 14px;
    color: grey;
`;

const InputField = styled(TextField)`
    && {
        margin-bottom: 16px;
    }
`;

const VerifyButton = styled(Button)`
    && {
        font-size: 12px;
        width: 120px;
        padding: 4px;
        margin-bottom: 6px;
    }
`;

const PurchaseButton = styled(Button)`
    && {
        width: 100%;
        position: relative;
    }
`;

export const PaymentSetDialog = (props) => {
    const {
        productSet,
        extension,
        phoneNumber,
        name,
        onChangeName,
        onChangePhoneNumber,
        onKeyDownOnPaymentDialog,
        isDialogOpen,
        closePaymentDialog,
        purchase,
        authCode,
        onChangeAuthCode,
        isSentVerifiedCode,
        setIsSentVerifiedCode,
    } = props;

    const [verifyAuthCodeLoading, setVerifyAuthCodeLoading] = useState(false);

    const handleClickVerifyCode = () => {
        setVerifyAuthCodeLoading(true);
        const body = {
            operationName: 'SendAuthVerifySMS',
            variables: { input: { authenticationType: 'SINGUP', phoneNumber } },
            query: `mutation SendAuthVerifySMS($input: SendAuthVerifySMSInput!) {
                sendAuthVerifySMS(input: $input) {
                    result
                    __typename
                }
            }`,
        };
        ajax({
            type: 'POST',
            url: GRAPHQL_URL,
            data: JSON.stringify(body),
            success: function () {
                setIsSentVerifiedCode(true);
                setVerifyAuthCodeLoading(false);
            },
            contentType: 'application/json',
            dataType: 'json',
        });
    };

    return (
        <CustomDialog onKeyDown={onKeyDownOnPaymentDialog} open={isDialogOpen} onClose={closePaymentDialog}>
            <DialogContent>
                <Title>{productSet && productSet.name}</Title>
                <Price>{productSet && changeCurrencyUnit(productSet.price)}원</Price>
                {!isEmpty(productSet?.products) && (
                    <ProductList>
                        {productSet.products.map((product, idx) => (
                            <ProductItem key={idx}>
                                {product.name} * {product.quantity}
                            </ProductItem>
                        ))}
                    </ProductList>
                )}
                <div>
                    <InputField
                        placeholder={extension ? '학생 이름' : '학부모 이름'}
                        name="name"
                        fullWidth
                        onChange={onChangeName}
                        error={!/^[ㄱ-ㅎㅏ-ㅣ가-힣a-z0-9_-].{1,10}$/.test(name)}
                        value={name}
                    />
                    <InputField
                        placeholder={extension ? '학생 연락처' : '학부모 연락처'}
                        name="phoneNumber"
                        type="number"
                        fullWidth
                        onChange={onChangePhoneNumber}
                        error={!/^(?=.*[0-9]).{10,11}$/.test(phoneNumber)}
                        value={phoneNumber}
                        InputProps={{
                            endAdornment: !extension && (
                                <VerifyButton
                                    variant="outlined"
                                    disabled={phoneNumber.length > 11 || phoneNumber.length < 10 || verifyAuthCodeLoading}
                                    onClick={handleClickVerifyCode}
                                >
                                    {isSentVerifiedCode ? '재전송' : '인증번호 전송'}
                                </VerifyButton>
                            ),
                        }}
                    />
                    {isSentVerifiedCode && (
                        <InputField
                            placeholder="인증번호"
                            name="phoneVerifyCode"
                            type="number"
                            fullWidth
                            value={authCode}
                            onChange={onChangeAuthCode}
                            disabled={verifyAuthCodeLoading}
                        />
                    )}
                </div>
                <PurchaseButton color="secondary" variant="contained" onClick={purchase}>
                    결제
                </PurchaseButton>
            </DialogContent>
        </CustomDialog>
    );
};
