export const personalTeachingDefault = {
    title: '밀당PT',
    description: '오로지 한 명의 학생을 위한',
    cardTitle: '이젠 집에서도 온라인으로 1:1 관리를 받아보세요.',
    firstCard: {
        title: '내신대비',
        descriptions: ['전국 교과서 & 부교재 내신 완벽대비', '변형문제 + 서술형 완벽대비'],
    },
    secondCard: {
        title: '수능대비',
        descriptions: ['노베이스 ~ 1등급까지 수준별 커리큘럼', '최대 250개 강의 + 개별 커리큘럼'],
    },
};
