import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/purchase-page';
import {
    PurchaseSectionDescription,
    PurchaseSectionInnerWrapper,
    PurchaseSectionTitle,
    PurchaseSectionTitleWrapper,
    PurchaseSectionWrapper,
} from './SectionCommonStyles';

const SectionBodyWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 0.5rem;
    }
`;

const SectionBodyCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    min-width: 15rem;
    background: linear-gradient(180deg, #ffffff77 0%, #ffffff 89.05%);
    border-radius: 2rem;
    padding: 2rem;
    gap: 1rem;
    border: 2px solid #ffffff;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 1rem;
        gap: 0.5rem;
    }
`;

const SectionBodyCardTitle = styled.div`
    font-size: 1.5rem;
    font-weight: 800;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 1rem;
    }
`;

const SectionBodyCardDescription = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 0.25rem;
        font-size: 0.75rem;
    }
`;

const SectionBodyCardImage = styled.img`
    width: 6rem;
    height: 6rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        width: 4rem;
        height: 4rem;
    }
`;

export const PurchaseBenefitSection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseSectionWrapper color={material.color}>
            <PurchaseSectionInnerWrapper>
                <PurchaseSectionTitleWrapper>
                    <PurchaseSectionDescription>공부 PT가 처음이라면</PurchaseSectionDescription>
                    <PurchaseSectionTitle>특별한 혜택</PurchaseSectionTitle>
                </PurchaseSectionTitleWrapper>
                <SectionBodyWrapper>
                    <SectionBodyCard color={material.color}>
                        <SectionBodyCardImage src="/images/payment-direct/purchase/payback-icon.png" alt={'payback-icon'} />
                        <SectionBodyCardTitle>{material.refundAmount ?? 10}만원 특별 혜택</SectionBodyCardTitle>
                        <SectionBodyCardDescription>
                            <div>수강 연장 등록 시,</div>
                            <div>{material.refundAmount ?? 10}만원을 환급해드립니다.</div>
                        </SectionBodyCardDescription>
                    </SectionBodyCard>
                    <SectionBodyCard color={material.color}>
                        <SectionBodyCardImage src="/images/payment-direct/purchase/refund-icon.png" alt={'refund-icon'} />
                        <SectionBodyCardTitle>환불보장 {material.refundDay ?? 7}일</SectionBodyCardTitle>
                        <SectionBodyCardDescription>
                            <div>개강 후, {material.refundDay ?? 7}일 이내 환불할 경우</div>
                            <div>100% 환불을 보장해드립니다.</div>
                        </SectionBodyCardDescription>
                    </SectionBodyCard>
                </SectionBodyWrapper>
            </PurchaseSectionInnerWrapper>
        </PurchaseSectionWrapper>
    );
};
