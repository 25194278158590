import React, { useContext, useEffect, useRef } from 'react';
import { PaymentDirectInfoContext, PaymentDirectInfoContextStore } from '../../contexts/payment-direct.context';
import { googleAnalyticsConfig, googleAnalyticsEvent } from '../../utils/google-analytics';
import { Footer } from '../../components/common/Footer';
import styled from 'styled-components';
import { moveToPurchasePage } from '../../utils/move-to-purchase-page';
import { getPaymentDirectImage, ImageType } from '../../constants/payment-direct-page';

const MobileImage = styled.img`
    display: block;
    width: 100%;
`;

const DesktopImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #080c08 71.9%, #10de05 28.1%);
`;

const DesktopImage = styled.img`
    display: block;
    width: ${(props) => props.desktopContentWidth}px;
`;

const CardListDesktopWrapper = styled.div`
    display: flex;
    justify-content: center;
    background: linear-gradient(180deg, #81ff5a 0%, #b6f44f 50%, #b6f44f 80%, #81ff5a 100%);
`;

const CardListMobileWrapper = styled.div`
    display: block;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 11vw 5vw;
    background-image: url('/images/payment-direct/main/mobile/3-addcitive-background.png');
    background-size: contain;
`;

export const PaymentDirectAddictive = (props) => {
    const { paymentPage } = props;
    useEffect(() => {
        googleAnalyticsConfig('공부 중독반 결제 페이지', '/direct/addictive');
    }, []);

    return (
        <PaymentDirectInfoContext>
            <PaymentDirectAddictiveContent paymentPage={paymentPage} />
        </PaymentDirectInfoContext>
    );
};

const PaymentDirectAddictiveContent = (props) => {
    const { paymentPage } = props;
    const { isMobile, scrollY, readsProductListSection, setReadsProductListSection, desktopContentWidth } = useContext(PaymentDirectInfoContextStore);
    const productListSectionRef = useRef(null);

    useEffect(() => {
        if (readsProductListSection) return;
        const productListSectionTop = (productListSectionRef?.current as any)?.offsetTop;
        if (scrollY <= productListSectionTop) return;

        setReadsProductListSection(true);
        googleAnalyticsEvent('scroll', 'scroll', 'productionList');
    }, [readsProductListSection, scrollY, setReadsProductListSection]);

    if (isMobile) {
        return (
            <>
                <MobileImage src={getPaymentDirectImage(paymentPage, 'header', ImageType.MOBILE)} alt="2" />
                <div ref={productListSectionRef}>
                    <PaymentCardListAddictiveMobile paymentPage={paymentPage} />
                </div>
                <Footer />
            </>
        );
    } else {
        return (
            <>
                <DesktopImageWrapper>
                    <DesktopImage src={getPaymentDirectImage(paymentPage, 'header', ImageType.DESKTOP)} alt="2" desktopContentWidth={desktopContentWidth} />
                </DesktopImageWrapper>
                <div ref={productListSectionRef}>
                    <PaymentCardListAddictiveDesktop paymentPage={paymentPage} />
                </div>
                <Footer />
            </>
        );
    }
};

const PaymentCardListAddictiveDesktop = (props) => {
    const { paymentPage } = props;
    const { desktopContentWidth } = useContext(PaymentDirectInfoContextStore);

    return (
        <div>
            <CardListDesktopWrapper>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: desktopContentWidth,
                        padding: '70px 0px',
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                        <img
                            style={{ display: 'block', width: '55%' }}
                            src={getPaymentDirectImage(paymentPage, 'text', ImageType.DESKTOP)}
                            alt="addictive-text"
                        />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%', padding: '0px 50px' }}>
                        <div style={{ flex: 1 }}>
                            <img
                                onClick={() => moveToPurchasePage(paymentPage, 0)}
                                style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                                src="/images/payment-direct/main/desktop/addictive-middle-math.png"
                                alt="middle-math"
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <img
                                onClick={() => moveToPurchasePage(paymentPage, 1)}
                                style={{ display: 'block', cursor: 'pointer', width: '100%' }}
                                src="/images/payment-direct/main/desktop/addictive-middle-english.png"
                                alt="middle-english"
                            />
                        </div>
                    </div>
                </div>
            </CardListDesktopWrapper>
        </div>
    );
};

const PaymentCardListAddictiveMobile = (props) => {
    const { paymentPage } = props;

    return (
        <CardListMobileWrapper>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <img style={{ display: 'block', width: '75%' }} src={getPaymentDirectImage(paymentPage, 'text', ImageType.MOBILE)} alt="addictive-text" />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <img
                    onClick={() => moveToPurchasePage(paymentPage, 0)}
                    style={{ display: 'block', width: '49.5%' }}
                    src="/images/payment-direct/main/mobile/addictive-middle-math.png"
                    alt="middle-math"
                />
                <img
                    onClick={() => moveToPurchasePage(paymentPage, 1)}
                    style={{ display: 'block', width: '49.5%', marginLeft: '1%' }}
                    src="/images/payment-direct/main/mobile/addictive-middle-english.png"
                    alt="middle-english"
                />
            </div>
        </CardListMobileWrapper>
    );
};
