import React, { useContext } from 'react';
import { FormControl, MenuItem, Select, styled as muiStyled } from '@material-ui/core';
import { PurchaseDirectInfoContextStore } from '../../contexts/purchase-direct.context';
import { googleAnalyticsEvent } from '../../utils/google-analytics';

const SelectBox = muiStyled(Select)({
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '140%',
});

export const ProductSelectMenu = () => {
    const { purchaseConfigure } = useContext(PurchaseDirectInfoContextStore);
    const { isMultiplePayment } = purchaseConfigure;

    if (isMultiplePayment) {
        return <ProductMultipleSelectMenu />;
    } else {
        return <ProductSingleSelectMenu />;
    }
};

const ProductMultipleSelectMenu = () => {
    const { products, productListOnCart, setProductListOnCart } = useContext(PurchaseDirectInfoContextStore);

    const onChangeProduct = (event) => {
        const product = products.find((product) => product.id === event.target.value);
        if (productListOnCart.every((item) => item.id !== product.id)) {
            const newProductListOnCart = productListOnCart.concat(product);
            setProductListOnCart(newProductListOnCart);
        }
    };

    return (
        <FormControl fullWidth={true} size={'small'}>
            <SelectBox variant={'outlined'} value={0} onChange={onChangeProduct}>
                <MenuItem value={0} disabled={true}>
                    프로그램
                </MenuItem>
                {products.map((product, key) => {
                    return (
                        <MenuItem key={key} value={product.id}>
                            {product.name}
                        </MenuItem>
                    );
                })}
            </SelectBox>
        </FormControl>
    );
};

const ProductSingleSelectMenu = () => {
    const { products, selectedProduct, setSelectedProduct, setProductListOnCart, setAmount } = useContext(PurchaseDirectInfoContextStore);

    const onChangeProduct = (event) => {
        const product = products.find((product) => product.id === event.target.value);
        setSelectedProduct(product);
        setProductListOnCart([product]);
        setAmount(product.price);

        googleAnalyticsEvent('click', 'button', `direct_product_card`);
        googleAnalyticsEvent('click', 'button', `direct_product_card_${product.name}`);
        googleAnalyticsEvent('click', 'button', `direct_product_card_${product.price}`);
    };

    return (
        <FormControl fullWidth={true}>
            <SelectBox variant={'outlined'} value={selectedProduct?.id ?? ''} onChange={onChangeProduct}>
                {products.map((product, key) => {
                    return (
                        <MenuItem key={key} value={product.id}>
                            {product.name}
                        </MenuItem>
                    );
                })}
            </SelectBox>
        </FormControl>
    );
};
