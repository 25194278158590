import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH } from '../../../constants/constants';
import { PurchaseConfigureItem } from '../../../constants/purchase-page';
import {
    PurchaseSectionDescription,
    PurchaseSectionInnerWrapper,
    PurchaseSectionTitle,
    PurchaseSectionTitleWrapper,
    PurchaseSectionWrapper,
} from './SectionCommonStyles';
import Strategy1 from './assets/Strategy1';
import Strategy2 from './assets/Strategy2';
import Strategy3 from './assets/Strategy3';
import Strategy4 from './assets/Strategy4';

const SectionBodyWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        gap: 0.5rem;
    }
`;

const SectionBodyCard = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding: 2rem;
    gap: 1rem;
    border: 2px solid ${(props) => props.color};

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        gap: 0.5rem;
    }
`;

const SectionBodyCardDescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const SectionBodyCardOrderNumber = styled.div`
    font-size: 1rem;
    font-weight: 800;
    color: #999999;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        display: none;
        font-size: 0.75rem;
    }
`;

const SectionBodyCardDescription = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 0.75rem;
    }
`;

const SectionBodyCardDescriptionDisclaimer = styled.div`
    display: flex;
    flex-direction: column;
    color: #999999;
    font-size: 0.875rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        font-size: 0.625rem;
    }
`;

export const PurchaseStrategySection = (props: { material: PurchaseConfigureItem }) => {
    const { material } = props;
    return (
        <PurchaseSectionWrapper color={material.color + '44'}>
            <PurchaseSectionInnerWrapper>
                <PurchaseSectionTitleWrapper>
                    <PurchaseSectionDescription>학원, 과외와 다른 이유</PurchaseSectionDescription>
                    <PurchaseSectionTitle>차별화된 전략</PurchaseSectionTitle>
                </PurchaseSectionTitleWrapper>
                <SectionBodyWrapper>
                    <SectionBodyCard color={material.color + '99'}>
                        <Strategy1 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>01</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>수업 시간 외에도</div>
                                <div>
                                    <b>언제든지 질문</b> 가능
                                </div>
                                <SectionBodyCardDescriptionDisclaimer>(주말/공휴일 차주 평일 답변)</SectionBodyCardDescriptionDisclaimer>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                    <SectionBodyCard color={material.color + '99'}>
                        <Strategy2 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>02</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>재학생, 프리랜서가 아닌</div>
                                <div>
                                    <b>전문 선생님</b>
                                </div>
                                <SectionBodyCardDescriptionDisclaimer>(정직원 선생님만 배정)</SectionBodyCardDescriptionDisclaimer>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                    <SectionBodyCard color={material.color + '99'}>
                        <Strategy3 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>03</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>화상과외는 못하는</div>
                                <div>
                                    <b>복습 프로세스</b>
                                </div>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                    <SectionBodyCard color={material.color + '99'}>
                        <Strategy4 color={material.color} width={'5rem'} height={'5rem'} />
                        <SectionBodyCardDescriptionWrapper>
                            <SectionBodyCardOrderNumber>04</SectionBodyCardOrderNumber>
                            <SectionBodyCardDescription>
                                <div>숙제가 필요 없는</div>
                                <div>
                                    <b>당일 완벽 학습 구조</b>
                                </div>
                            </SectionBodyCardDescription>
                        </SectionBodyCardDescriptionWrapper>
                    </SectionBodyCard>
                </SectionBodyWrapper>
            </PurchaseSectionInnerWrapper>
        </PurchaseSectionWrapper>
    );
};
