import * as React from 'react';

function Strategy4(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16229" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <rect y="0.5" width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16229)">
                <path
                    d="M88.3793 44.363C89.2153 47.6551 89.6595 51.1169 89.6595 54.6703C89.6595 77.7668 70.9263 96.5 47.8298 96.5C24.7332 96.5 6 77.7668 6 54.6703C6 35.2446 19.2335 18.9281 37.1698 14.2122M92.847 30.3066C84.2642 14.4865 67.595 3.70902 48.3915 3.5C48.091 3.5 47.8298 3.74821 47.8298 4.04867V53.9909C47.8298 54.2914 48.1563 54.4874 48.4307 54.3567L92.625 31.0643C92.8993 30.9206 93.0038 30.5809 92.847 30.3196V30.3066Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Strategy4;
