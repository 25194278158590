import React, { useEffect, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { PaymentPageOverallType, ProductGroupInput, ProductInput, ProductOptionInput, ProductCompositionType } from '../../constants/payment-page';
import { customFetch } from '../../utils/custom-fetch';
import { EditProductDialog } from '../../components/manager/EditProductDialog';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DateTime } from 'luxon';
import { isEmpty, isNil } from '@fxts/core';
import { styled as muiStyled } from '@material-ui/core/styles';
import { ViewType } from '../../constants/payment-page';

const AppBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    background-color: white;
    padding: 0rem 1rem;
`;

const BodyWrapper = styled(Card)`
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    margin: 2rem auto;
    background-color: white;
`;

const FuctionWrapper = styled.div`
    display: flex;
    gap: 4px;
    justify-content: right;
`;

const SelectBox = muiStyled(Select)({
    fontFamily: 'Pretendard',
    fontStyle: 'normal',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '140%',
});

const initialProductGroup: ProductGroupInput = {
    title: '',
    attendTime: '',
    lastAttendTime: '',
    hasExtraCharge: false,
    products: [],
    orderIndex: 1,
};

export const EditPage = (props) => {
    const { isEdit, paymentPageId } = props;
    const [title, setTitle] = useState('');
    const [type, setType] = useState(PaymentPageOverallType.NEW);
    const [isRefundable, setIsRefundable] = useState(false);
    const [productGroups, setProductGroups] = useState<ProductGroupInput[]>([initialProductGroup]);
    const [isOpenEditProductDialog, setIsOpenEditProductDialog] = useState(false);
    const [isEditProductDialog, setIsEditProductDialog] = useState(false);
    const [selectedProductGroupIndex, setSelectedProductGroupIndex] = useState(0);
    const [selectedProductIndex, setSelectedProductIndex] = useState(0);
    const [viewType, setViewType] = useState<ViewType>(ViewType.NORMAL);
    const [productCompositionType, setProductCompositionType] = useState<ProductCompositionType>(ProductCompositionType.PRODUCT);

    const [startDateOfClass, setStartDateOfClass] = useState<Date | null>(null);
    const [endDateOfClass, setEndDateOfClass] = useState<Date | null>(null);
    const [usesAdditionalSetting, setUsesAdditionalSetting] = useState<boolean>(false);

    const selectedProductGroup = productGroups[selectedProductGroupIndex] ?? undefined;
    const selectedProduct = selectedProductGroup?.products[selectedProductIndex] ?? undefined;

    useEffect(() => {
        if (isEdit === true) getPaymentPage(paymentPageId);
    }, [isEdit, paymentPageId]);

    const getPaymentPage = async (id) => {
        const {
            data: { paymentPage },
        } = await customFetch('GET', `/paymentPages/${id}`);

        const { title, type, options } = paymentPage;
        const { isRefundable, productGroups, viewType, productCompositionType, startDateOfClass, endDateOfClass } = options;
        setTitle(title);
        setType(type);
        setIsRefundable(isRefundable);
        setProductGroups(productGroups);

        if (!isNil(viewType)) setViewType(viewType);
        if (!isNil(productCompositionType)) setProductCompositionType(productCompositionType);
        if (!isNil(startDateOfClass)) setStartDateOfClass(new Date(startDateOfClass));
        if (!isNil(endDateOfClass)) setEndDateOfClass(new Date(endDateOfClass));
    };

    const createPaymentPage = async () => {
        if (!checkRequiredFiled()) return alert('필수항목을 확인하고 입력해주세요.');

        const options: ProductOptionInput = {
            isRefundable,
            productGroups,
        };
        const data = { title, type, options };
        const result = await customFetch('POST', '/paymentPages', data);
        if (result.meta.code !== 0) return alert(result.meta.message);

        const pageUrl = `${global.location.origin}/manager`;
        global.location.href = pageUrl;
    };

    const editPaymentPage = async () => {
        if (!checkRequiredFiled()) return alert('필수항목을 확인하고 입력해주세요.');

        const options: ProductOptionInput = {
            isRefundable,
            productGroups,
            viewType: !isNil(viewType) ? viewType : undefined,
            productCompositionType: !isNil(productCompositionType) ? productCompositionType : undefined,
            startDateOfClass: !isNil(startDateOfClass) ? DateTime.fromJSDate(startDateOfClass).toFormat('yyyy-MM-dd') : undefined,
            endDateOfClass: !isNil(endDateOfClass) ? DateTime.fromJSDate(endDateOfClass).toFormat('yyyy-MM-dd') : undefined,
        };
        const data = { title, type, options };
        const result = await customFetch('PUT', `/paymentPages/${paymentPageId}`, data);
        if (result.meta.code !== 0) return alert(result.meta.message);

        const pageUrl = `${global.location.origin}/manager`;
        global.location.href = pageUrl;
    };

    const checkRequiredFiled = () => {
        const isTitleFilled = !isEmpty(title);
        const isProductsFilled = productGroups.every((productGroup) => !isEmpty(productGroup.products));
        return isTitleFilled && isProductsFilled;
    };

    const onChangeTitle = (event) => {
        const { value } = event.target;
        setTitle(value);
    };

    const onCheckIsRefundable = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const newValue = value === 'refundable';
        setIsRefundable(newValue);
    };

    const onCheckType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setType(value as PaymentPageOverallType);
    };

    const handleProductGroupTitle = (index, event) => {
        const { value } = event.target;
        const productGroupsCopied = JSON.parse(JSON.stringify(productGroups));
        productGroupsCopied[index].title = value;

        setProductGroups(productGroupsCopied);
    };

    const handleProductGroupAttendTime = (index, event) => {
        const { value } = event.target;
        const productGroupsCopied = JSON.parse(JSON.stringify(productGroups));
        productGroupsCopied[index].attendTime = value;

        setProductGroups(productGroupsCopied);
    };

    const handleProductGroupLastAttendTime = (index, event) => {
        const { value } = event.target;
        const productGroupsCopied = JSON.parse(JSON.stringify(productGroups));
        productGroupsCopied[index].lastAttendTime = value;

        setProductGroups(productGroupsCopied);
    };

    const handleViewType = (event) => {
        setViewType(event.target.value);
    };

    const handleStartDateChange = (date: Date | null) => {
        setStartDateOfClass(date);
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDateOfClass(date);
    };

    const openProductAddDialog = (index: number) => {
        setSelectedProductGroupIndex(index);
        setIsEditProductDialog(false);
        setIsOpenEditProductDialog(true);
    };

    const openProductEditDialog = (productGroupIndex: number, productIndex: number) => {
        setSelectedProductGroupIndex(productGroupIndex);
        setSelectedProductIndex(productIndex);
        setIsEditProductDialog(true);
        setIsOpenEditProductDialog(true);
    };

    const closeProductEditDialog = () => {
        setIsOpenEditProductDialog(false);
    };

    const addProductGroup = () => {
        const productGroupsCopied = JSON.parse(JSON.stringify(productGroups));
        productGroupsCopied.push({
            title: initialProductGroup.title,
            attendTime: initialProductGroup.attendTime,
            lastAttendTime: initialProductGroup.lastAttendTime,
            hasExtraCharge: initialProductGroup.hasExtraCharge,
            products: initialProductGroup.products,
            orderIndex: productGroupsCopied.length + 1,
        });
        setProductGroups(productGroupsCopied);
    };

    const editProduct = (product: ProductInput) => {
        const productGroupsCopied = JSON.parse(JSON.stringify(productGroups));
        if (isEditProductDialog) {
            productGroupsCopied[selectedProductGroupIndex].products[selectedProductIndex] = product;
        } else {
            productGroupsCopied[selectedProductGroupIndex].products.push(product);
        }
        setProductGroups(productGroupsCopied);
        closeProductEditDialog();
    };

    const deleteProductGroup = (productGroupIndex: number) => {
        const productGroupsCopied = JSON.parse(JSON.stringify(productGroups));
        const newProductGroup = productGroupsCopied.filter((productGroup, index) => index !== productGroupIndex);
        setProductGroups(newProductGroup);
    };

    const deleteProduct = (productGroupIndex: number, productIndex: number) => {
        const productGroupsCopied = JSON.parse(JSON.stringify(productGroups));
        productGroupsCopied[productGroupIndex].products = productGroupsCopied[productGroupIndex].products.filter((product, index) => index !== productIndex);
        setProductGroups(productGroupsCopied);
    };

    useEffect(() => {
        if (viewType !== ViewType.NORMAL) setUsesAdditionalSetting(true);
        if (!isNil(startDateOfClass)) setUsesAdditionalSetting(true);
        if (!isNil(endDateOfClass)) setUsesAdditionalSetting(true);
    }, [viewType, startDateOfClass, endDateOfClass]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ backgroundColor: 'whitesmoke', minHeight: '100vh' }}>
                <AppBar>
                    <Typography variant={'h6'}>결제 페이지</Typography>
                    {isEdit ? (
                        <Button onClick={editPaymentPage} variant={'contained'} color={'primary'}>
                            수정
                        </Button>
                    ) : (
                        <Button onClick={createPaymentPage} variant={'contained'} color={'primary'}>
                            생성
                        </Button>
                    )}
                </AppBar>
                <Container>
                    <BodyWrapper>
                        <Typography variant={'subtitle1'}>기본 정보</Typography>
                        <Divider style={{ margin: '1rem 0rem' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
                                <FieldComponent name={'제목'}>
                                    <TextField onChange={onChangeTitle} value={title} size={'small'} variant={'outlined'} />
                                </FieldComponent>
                                <FieldComponent name={'타입'}>
                                    <RadioGroup row={true} onChange={onCheckType} value={type}>
                                        <FormControlLabel label={'신규'} value={PaymentPageOverallType.NEW} control={<Radio color={'primary'} />} />
                                        <FormControlLabel label={'연장'} value={PaymentPageOverallType.EXTENSION} control={<Radio color={'primary'} />} />
                                    </RadioGroup>
                                </FieldComponent>
                                <FieldComponent name={'환불 가능 여부'}>
                                    <RadioGroup row={true} onChange={onCheckIsRefundable} value={isRefundable === true ? 'refundable' : 'non-refundable'}>
                                        <FormControlLabel label={'환불 가능'} value={'refundable'} control={<Radio color={'primary'} />} />
                                        <FormControlLabel label={'환불 불가능'} value={'non-refundable'} control={<Radio color={'primary'} />} />
                                    </RadioGroup>
                                </FieldComponent>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>추가 설정</span>
                                <Switch size={'small'} onChange={(event, checked) => setUsesAdditionalSetting(checked)} checked={usesAdditionalSetting} />
                            </div>
                            {usesAdditionalSetting && (
                                <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
                                    <FieldComponent name={'화면 유형'}>
                                        <FormControl size={'small'}>
                                            <SelectBox variant={'outlined'} value={viewType} onChange={handleViewType}>
                                                <MenuItem value={ViewType.NORMAL}>기본</MenuItem>
                                                <MenuItem value={ViewType.GRADE_3}>고3 결제</MenuItem>
                                                <MenuItem value={ViewType.GRADE_3_EXTENSION}>고3 연장 결제</MenuItem>
                                            </SelectBox>
                                        </FormControl>
                                    </FieldComponent>
                                    <FieldComponent name={'권한 시작일'}>
                                        <DatePicker
                                            allowKeyboardControl={false}
                                            disableToolbar={true}
                                            inputVariant="standard"
                                            format="yyyy-MM-dd"
                                            size="small"
                                            InputProps={{ inputProps: { style: { width: '10ch' } } }}
                                            value={startDateOfClass}
                                            onChange={handleStartDateChange}
                                            invalidDateMessage={'올바른 형식의 날짜를 입력해주세요.'}
                                        />
                                    </FieldComponent>
                                    <FieldComponent name={'권한 종료일'}>
                                        <DatePicker
                                            allowKeyboardControl={false}
                                            disableToolbar={true}
                                            inputVariant="standard"
                                            format="yyyy-MM-dd"
                                            size="small"
                                            InputProps={{ inputProps: { style: { width: '10ch' } } }}
                                            value={endDateOfClass}
                                            onChange={handleEndDateChange}
                                            invalidDateMessage={'올바른 형식의 날짜를 입력해주세요.'}
                                        />
                                    </FieldComponent>
                                </div>
                            )}
                        </div>
                    </BodyWrapper>
                    <BodyWrapper>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography variant={'subtitle1'}>상품 그룹</Typography>
                                <ButtonGroup size="small" variant={'text'} style={{ display: 'flex', justifyContent: 'center' }}>
                                    {productGroups.map((productGroup, productGroupIndex) => {
                                        return (
                                            <Button key={productGroupIndex} onClick={setSelectedProductGroupIndex.bind(null, productGroupIndex)}>
                                                {productGroupIndex + 1}
                                            </Button>
                                        );
                                    })}
                                </ButtonGroup>

                                <Button onClick={addProductGroup} variant={'contained'} color={'primary'} size={'small'}>
                                    추가
                                </Button>
                            </div>
                            <Divider style={{ margin: '1rem 0rem' }} />
                            {selectedProductGroup && (
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <FieldComponent name={'상품 그룹 제목'}>
                                            <TextField
                                                onChange={handleProductGroupTitle.bind(null, selectedProductGroupIndex)}
                                                value={selectedProductGroup.title}
                                                size={'small'}
                                                variant={'outlined'}
                                            />
                                        </FieldComponent>
                                        <Button
                                            onClick={deleteProductGroup.bind(null, selectedProductGroupIndex)}
                                            variant={'contained'}
                                            size={'small'}
                                            color={'primary'}
                                        >
                                            삭제
                                        </Button>
                                    </div>
                                    <div style={{ display: 'flex', gap: '2rem', flexWrap: 'wrap' }}>
                                        <FieldComponent name={'출석 시간'}>
                                            <TextField
                                                onChange={handleProductGroupAttendTime.bind(null, selectedProductGroupIndex)}
                                                value={selectedProductGroup.attendTime}
                                                size={'small'}
                                                variant={'outlined'}
                                                fullWidth={true}
                                            />
                                        </FieldComponent>
                                        <FieldComponent name={'마지막 출석 시간'}>
                                            <TextField
                                                onChange={handleProductGroupLastAttendTime.bind(null, selectedProductGroupIndex)}
                                                value={selectedProductGroup.lastAttendTime}
                                                size={'small'}
                                                variant={'outlined'}
                                                fullWidth={true}
                                            />
                                        </FieldComponent>
                                    </div>
                                    <FieldComponent name={'상품 목록'}>
                                        <TableContainer component={Paper}>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>제목</TableCell>
                                                        <TableCell>기본가격</TableCell>
                                                        <TableCell>실제가격</TableCell>
                                                        <TableCell>이용 기간</TableCell>
                                                        <TableCell>환불 가능일</TableCell>
                                                        <TableCell></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {selectedProductGroup.products.map((product, productIndex) => {
                                                        return (
                                                            <TableRow key={productIndex}>
                                                                <TableCell>{product.name}</TableCell>
                                                                <TableCell>{product.originalPrice}</TableCell>
                                                                <TableCell>{product.price}</TableCell>
                                                                <TableCell>{product.duration}</TableCell>
                                                                <TableCell>{product.refundDate}</TableCell>
                                                                <TableCell>
                                                                    <FuctionWrapper>
                                                                        <Button
                                                                            onClick={openProductEditDialog.bind(null, selectedProductGroupIndex, productIndex)}
                                                                            variant={'contained'}
                                                                            color={'primary'}
                                                                            size={'small'}
                                                                        >
                                                                            수정
                                                                        </Button>
                                                                        <Button
                                                                            onClick={deleteProduct.bind(null, selectedProductGroupIndex, productIndex)}
                                                                            variant={'contained'}
                                                                            color={'primary'}
                                                                            size={'small'}
                                                                        >
                                                                            삭제
                                                                        </Button>
                                                                    </FuctionWrapper>
                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                                    <TableRow>
                                                        <TableCell>
                                                            <Button
                                                                onClick={openProductAddDialog.bind(null, selectedProductGroupIndex)}
                                                                variant={'contained'}
                                                                color={'primary'}
                                                                size={'small'}
                                                            >
                                                                추가
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </FieldComponent>
                                </div>
                            )}
                        </div>
                    </BodyWrapper>
                </Container>
                <EditProductDialog
                    selectedProduct={selectedProduct}
                    isEdit={isEditProductDialog}
                    isOpen={isOpenEditProductDialog}
                    onClose={closeProductEditDialog}
                    onSubmit={editProduct}
                />
            </div>
        </MuiPickersUtilsProvider>
    );
};

const FiledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

const FieldComponent = (props) => {
    const { name, children } = props;
    return (
        <FiledWrapper>
            <Typography variant={'subtitle1'}>{name}</Typography>
            {children}
        </FiledWrapper>
    );
};
