import React, { useState, useEffect, useRef } from 'react';

function Deadline() {
    let [hours, setHours] = useState('00');
    let [minutes, setMinutes] = useState('00');
    let [seconds, setSeconds] = useState('00');
    let timer = useRef<any | null>(null);

    const getTime = () => {
        let today = new Date();
        let remainHours: number | string = 23 - today.getHours();
        let remainMinutes: number | string = 59 - today.getMinutes();
        let remainSeconds: number | string = 59 - today.getSeconds();

        remainHours = remainHours < 10 ? '0' + remainHours : `${remainHours}`;
        remainMinutes = remainMinutes < 10 ? '0' + remainMinutes : `${remainMinutes}`;
        remainSeconds = remainSeconds < 10 ? '0' + remainSeconds : `${remainSeconds}`;

        setHours(remainHours);
        setMinutes(remainMinutes);
        setSeconds(remainSeconds);
    };

    useEffect(() => {
        timer.current = setInterval(getTime, 1000);
        return () => {
            clearInterval(timer.current);
        };
    }, []);

    return (
        <span className={'timer'}>
            {hours}:{minutes}:{seconds}
        </span>
    );
}

export default Deadline;
