import { ProductSet } from './product-set';

export enum ViewType {
    NORMAL = 'NORMAL',
    GRADE_3 = 'GRADE_3',
    GRADE_3_EXTENSION = 'GRADE_3_EXTENSION',
}

export enum ProductCompositionType {
    PRODUCT = 'PRODUCT',
    PRODUCT_SET = 'PRODUCT_SET',
}

export enum PaymentPageOverallType {
    NEW = 'NEW',
    EXTENSION = 'EXTENSION',
}

export enum Subject {
    MATH = 'MATH',
    ENGLISH = 'ENGLISH',
    KOREAN = 'KOREAN',
}

export interface ProductOptionInput {
    isRefundable: boolean;
    productGroups: ProductGroupInput[];
    viewType?: string;
    productCompositionType?: string;
    startDateOfClass?: string;
    endDateOfClass?: string;
}

export interface ProductGroupInput {
    title: string;
    attendTime: string;
    hasExtraCharge: boolean;
    lastAttendTime: string;
    products: ProductInput[];
    orderIndex: number;
}

export interface ProductInput {
    id?: number;
    name: string;
    description: string;
    pageCategory: string;
    originalPrice: number;
    price: number;
    duration: number;
    refundDate: number;
    isRecurring: boolean;
    subjects: Subject[];
    registerFormBaseUrl: string | null;
}

export interface PaymentPage {
    id: number;
    title: string;
    type: PaymentPageOverallType;
    paymentType: number;
    restUrl: string;
    path: string;
    options: PaymentPageOptions;
    deletedAt: null;
    updatedAt: string;
    createdAt: string;
}

export interface PaymentPageOptions {
    productGroups: ProductGroup[];
    isRefundable?: boolean;
    startDateOfClass?: string;
    endDateOfClass?: string;
    viewType?: ViewType;
    productCompositionType?: ProductCompositionType;
}

export interface ProductGroup {
    orderIndex: number;
    title?: string;
    attendTime?: string;
    hasExtraCharge?: boolean;
    lastAttendTime?: string;
}

export interface ProductGroupWithProductSets extends ProductGroup {
    productSets: ProductSet[];
}
