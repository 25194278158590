import { DateTime } from 'luxon';

type WeekDay = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';
const weekDays: WeekDay[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const MAX_DAY_OF_MONTH_COUNT = 31;

export const calculateDateAfterMonth = (baseDate: DateTime): DateTime => {
    const lastDay = getLastDayOfMonth(baseDate.toJSDate());
    return baseDate.set({ day: lastDay });
};

export const countDaysOfWeekBetweenDates = (date: Date, billingDay: number): number => {
    const startDate = DateTime.fromJSDate(date);
    const targetDays: WeekDay[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const targetDayIndexes = targetDays.map((targetDay) => {
        return weekDays.indexOf(targetDay);
    });

    const endDate = calculateDateAfterMonth(startDate);

    const countOrigin = Array.from({ length: endDate.diff(startDate, 'days').days + 1 }, (_, index) => startDate.plus({ days: index })).filter((date) => {
        return targetDayIndexes.includes(date.weekday - 1);
    }).length;

    // 한달 금액 기준을 28일로 잡고 있는데, 날짜수가 이것을 초과하면 28일로 조정함
    const count = countOrigin <= MAX_DAY_OF_MONTH_COUNT ? countOrigin : MAX_DAY_OF_MONTH_COUNT;

    if (getIsBillingDayPassed(date, billingDay)) return count + MAX_DAY_OF_MONTH_COUNT;
    return count;
};

export const getLastDayOfMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();
    return lastDayOfMonth;
};

export const getMonthDates = (currentDate: Date): Date[] => {
    const firstDayOfMonth = DateTime.fromJSDate(currentDate).startOf('month');
    const lastDayOfMonth = DateTime.fromJSDate(currentDate).endOf('month');

    const daysInMonth: Date[] = [];
    let currentDay = firstDayOfMonth;

    // 이전 달의 마지막 주의 일자부터 시작
    while (currentDay.weekday !== 7) {
        currentDay = currentDay.minus({ days: 1 });
        daysInMonth.unshift(currentDay.toJSDate());
    }

    // 현재 달의 날짜 추가
    currentDay = firstDayOfMonth;
    while (currentDay <= lastDayOfMonth) {
        daysInMonth.push(currentDay.toJSDate());
        currentDay = currentDay.plus({ days: 1 });
    }

    // 다음 달의 시작 부분 추가
    while (currentDay.weekday !== 7) {
        daysInMonth.push(currentDay.toJSDate());
        currentDay = currentDay.plus({ days: 1 });
    }

    return daysInMonth;
};

export const getFirstBillingDateOnStartDateOfClass = (startDateOfClass: Date, monthlyBillingDay: number): DateTime => {
    const startDate = DateTime.fromJSDate(startDateOfClass);
    const startYearMonth = startDate.toFormat('yyyy-MM');
    const formattedbillingDay = DateTime.fromObject({ day: monthlyBillingDay }).toFormat('dd');
    return DateTime.fromFormat(`${startYearMonth}-${formattedbillingDay}`, 'yyyy-MM-dd');
};

export const getIsBillingDayPassed = (startDateOfClass: Date, monthlyBillingDay: number): boolean => {
    const startDate = DateTime.fromJSDate(startDateOfClass);
    const firstBillingDate = getFirstBillingDateOnStartDateOfClass(startDateOfClass, monthlyBillingDay);

    return firstBillingDate <= startDate;
};

export const getNextBillingDate = (startDateOfClass: Date, monthlyBillingDay: number): DateTime => {
    const firstBillingDate = getFirstBillingDateOnStartDateOfClass(startDateOfClass, monthlyBillingDay);
    if (getIsBillingDayPassed(startDateOfClass, monthlyBillingDay)) return firstBillingDate.plus({ month: 1 });
    return firstBillingDate;
};

export const getLastClassDate = (startDateOfClass: Date, isPassedBillingDay: boolean): DateTime => {
    if (isPassedBillingDay) return DateTime.fromJSDate(startDateOfClass).plus({ months: 1 }).endOf('month');
    return DateTime.fromJSDate(startDateOfClass).endOf('month');
};
