import * as React from 'react';

function Strategy1(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16204" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <rect width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16204)">
                <path
                    d="M69.64 25.6C69.64 21.4 66.25 18 62.08 18H10.56C6.39 18 3 21.4 3 25.6V51.61C3 55.81 6.39 59.21 10.56 59.21H12.3V68.63C12.3 69.33 13.16 69.66 13.63 69.13L22.36 59.21H29.63M89.37 32.12H43.88C39.7 32.12 36.32 35.52 36.32 39.72V64.25C36.32 68.45 39.7 71.85 43.88 71.85H78.57L87.3 81.77C87.76 82.3 88.63 81.97 88.63 81.27V71.85H89.37C93.55 71.85 96.93 68.45 96.93 64.25V39.73C96.93 35.53 93.54 32.13 89.37 32.13V32.12Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Strategy1;
