import React from 'react';
import styled from 'styled-components';
import { PurchaseCardProps } from './PurchaseCard';
import { PurchaseCardStretched } from './PurchaseCardStretched';
import { MAX_TABLET_WIDTH } from '../../constants/constants';

const SectionWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
`;

const SectionTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SectionDescription = styled.div`
    font-size: 1.5rem;
    font-weight: 400;
    color: #999999;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 1rem;
    }
`;

const SectionTitle = styled.div`
    font-size: 2.5rem;
    font-weight: 800;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
        font-size: 1.66rem;
    }
`;

const CardListWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    flex-wrap: wrap;
`;

export interface PaymentSectionProps {
    title: string;
    description: string;
    math: PurchaseCardProps;
    english: PurchaseCardProps;
    korean?: PurchaseCardProps;
}

export const PaymentSection = (props: PaymentSectionProps) => {
    const { title, description, math, english, korean } = props;
    return (
        <SectionWrapper>
            <SectionTitleWrapper>
                <SectionDescription>{description}</SectionDescription>
                <SectionTitle>{title}</SectionTitle>
            </SectionTitleWrapper>
            <CardListWrapper>
                {korean !== undefined ? (
                    <PurchaseCardStretched title={korean.title} description={korean.description} color={korean.color} purchaseType={korean.purchaseType} />
                ) : null}
                <PurchaseCardStretched title={math.title} description={math.description} color={math.color} purchaseType={math.purchaseType} />
                <PurchaseCardStretched title={english.title} description={english.description} color={english.color} purchaseType={english.purchaseType} />
            </CardListWrapper>
        </SectionWrapper>
    );
};
