import React from 'react';
import styled from 'styled-components';
import { MAX_MOBILE_WIDTH, MAX_TABLET_WIDTH } from '../../constants/constants';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #222222;
    padding: 40px 0px;
    font-family: 'Noto Sans KR', Sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        padding: 1rem;
        align-items: flex-start;
    }
`;

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    gap: 24px;
    flex: none;
    flex-grow: 0;
    order: 0;
`;

const SectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 1rem;

    @media (max-width: ${MAX_MOBILE_WIDTH}px) {
        flex-direction: column;
        gap: 0;
    }
`;

export const Footer = () => {
    return (
        <Wrapper>
            <InnerWrapper>
                <div>
                    <img src="/images/payment-direct/main/desktop/ihateflyingbugs.png" alt={'ihateflyingbugs'} width={200} />
                </div>
                <SectionWrapper>
                    <div>
                        <div>아이헤이트플라잉버그스 ㈜</div>
                        <div>사업자등록번호 : 101-86-69359 | 대표자 : 박찬용</div>
                        <div>학원설립 운영등록번호 : 제5962호 밀당피티(PT)원격학원</div>
                        <div>신고기관명 : 서울특별시 남부교육지원청</div>
                    </div>
                    <div>
                        <div>통신판매업신고 : 2013-서울마포-1269</div>
                        <div>호스팅서비스: 아이헤이트플라잉버그스 ㈜</div>
                        <div>주소 : 서울 영등포구 여의대로 108 파크원 타워1, 39층</div>
                        <div>고객센터 : 1899-0508 | E-mail : cs@ihateflyingbugs.com</div>
                    </div>
                </SectionWrapper>
                <div>Ⓒ ihateflyingbugs all rights reserved.</div>
            </InnerWrapper>
        </Wrapper>
    );
};
