import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Introduction from '../pages/introduction/Introduction';
import Payment from '../pages/payment/Payment';
import Manager from '../pages/manager/Manager';
import { PaymentDirect } from '../pages/direct/PaymentDirect';
import { PurchaseDirect } from '../pages/purchase/PurchaseDirect';
import { isMobileByScreenWidth } from '../utils/screen';
import PaymentDirectResult from '../pages/direct/result/PaymentDirectResult';
import PaymentExtension from '../pages/extension/PaymentExtension';
import { DafaultPage } from '../pages/Default';
import { PaymentDirectElementary } from '../pages/direct/PaymentDirectElementary';
import { customFetch } from '../utils/custom-fetch';
import { EditPage } from '../pages/manager/EditPage';
import { PaymentPage, PaymentPageOverallType, ProductCompositionType, ViewType } from '../constants/payment-page';
import { PaymentDirectAddictive } from '../pages/direct/PaymentDirectAddictive';
import { head, isEmpty, isNil } from '@fxts/core';
import { validateFirstDateOfClass, validateMonthlyBillingDay } from '../utils/validate';
import PaymentExtensionMega from '../pages/extension/PaymentExtensionMega';
import PaymentMega from '../pages/payment/PaymentMega';
import { PaymentTotalShop } from '../pages/shop/PaymentTotalShop';
import { getPaymentDirectPage, PaymentDirectDisplayType, PaymentDirectPages } from '../constants/payment-direct-page';
import {
    TotalShopSectionList,
    TotalShopWeekendSectionList,
    TotalShopPromotionSectionList,
    TotalShopPromotionWeekendSectionList,
    TotalShop2SectionList,
} from '../constants/payment-total-shop-page';
import PaymentSet from '../pages/payment/PaymentSet';

function App() {
    const search = new URLSearchParams(window.location.search);
    const searchGet = (value: string) => search.get(value) ?? '';
    const token = decodeURIComponent(searchGet('token'));
    const direct = decodeURIComponent(searchGet('direct')) === '1';
    const paymentPageId = decodeURIComponent(searchGet('payment_page_id'));
    const impUid = decodeURIComponent(searchGet('imp_uid'));
    const merchantUid = decodeURIComponent(searchGet('merchant_uid'));
    const impSuccess = (() => {
        if (decodeURIComponent(searchGet('imp_success')) === 'true') return true;
        if (decodeURIComponent(searchGet('imp_success')) === 'false') return false;
        return true;
    })();
    const errorMsg = decodeURIComponent(searchGet('error_msg'));
    const purchaseType = decodeURIComponent(searchGet('purchase_type'));
    const startDateOfClass: Date | null = (() => {
        const value = decodeURIComponent(searchGet('start_date_of_class'));
        if (isEmpty(value)) return null;
        if (new Date(value).toString() === 'Invalid Date') return null;
        if (!validateFirstDateOfClass(new Date(value))) return null;

        return new Date(value);
    })();
    const monthlyBillingDay: number | null = (() => {
        const value = decodeURIComponent(searchGet('monthly_billing_day'));
        if (isEmpty(value)) return null;
        if (!validateMonthlyBillingDay(Number(value))) return null;
        return Number(value);
    })();

    const [width, setWidth] = useState<number>(window.innerWidth);
    const isMobile = isMobileByScreenWidth(width);
    const [pages, setPages] = useState<PaymentPage[]>([]);

    useEffect(() => {
        getPages();
    }, []);

    useEffect(() => {
        window.addEventListener('resize', () => setWidth(window.innerWidth));
    }, []);

    const getPages = async () => {
        const {
            data: { paymentPages },
        } = await customFetch('GET', '/paymentPages');

        const pages = paymentPages.map((page) => {
            page.path = '/' + page.restUrl;
            return page;
        });
        setPages(pages);
    };

    const getQueryParams = () => {
        const search = new URLSearchParams(window.location.search);
        const params = new URLSearchParams(search);
        const paramsKeys = Object(params).keys();
        const paramObj: any = {};
        for (let value of paramsKeys) {
            paramObj[value] = params.get(value);
        }
        return paramObj;
    };

    const queryParams = getQueryParams();

    return (
        <Router>
            <Switch>
                {/*결제 관리 페이지*/}
                <Route path="/manager">
                    <Manager />
                </Route>
                <Route path="/createPage">
                    <EditPage isEdit={false} />
                </Route>
                <Route path="/editPage">
                    <EditPage isEdit={true} paymentPageId={paymentPageId} />
                </Route>
                {/*상품 안내 페이지 - deprecated*/}
                <Route path="/introduction">
                    <Introduction isMobile={isMobile} />
                </Route>
                {/*결제 결과 페이지*/}
                <Route path="/result" exact={true}>
                    <PaymentDirectResult
                        isMobile={isMobile}
                        impUid={impUid}
                        merchantUid={merchantUid}
                        impSuccess={impSuccess}
                        errorMsg={errorMsg}
                        purchaseType={purchaseType}
                    />
                </Route>
                {/*직접 결제 결과 페이지*/}
                <Route path="/direct/result" exact={true}>
                    <PaymentDirectResult
                        isMobile={isMobile}
                        impUid={impUid}
                        merchantUid={merchantUid}
                        impSuccess={impSuccess}
                        errorMsg={errorMsg}
                        purchaseType={purchaseType}
                    />
                </Route>
                {/*직접 결제 상품 페이지*/}
                <Route path="/purchase" exact={true}>
                    <PurchaseDirect purchaseType={purchaseType} queryParams={queryParams} token={token} direct={true} />
                </Route>
                {/*통합 결제 페이지*/}
                <Route path={'/shop/new'} exact={true}>
                    <PaymentTotalShop sections={TotalShopSectionList} />
                </Route>
                <Route path={'/shop/weekend/new'} exact={true}>
                    <PaymentTotalShop sections={TotalShopWeekendSectionList} />
                </Route>
                <Route path={'/shop/promotion'} exact={true}>
                    <PaymentTotalShop sections={TotalShopPromotionSectionList} isPromotion={true} />
                </Route>
                <Route path={'/shop/weekend/promotion'} exact={true}>
                    <PaymentTotalShop sections={TotalShopPromotionWeekendSectionList} isPromotion={true} />
                </Route>
                <Route path={'/shop/2'} exact={true}>
                    <PaymentTotalShop sections={TotalShop2SectionList} />
                </Route>
                {/*직접 결제 페이지*/}
                {PaymentDirectPages.map((page, key) => {
                    if (page.displayType === PaymentDirectDisplayType.ELEMENTARY) {
                        return (
                            <Route path={page.path} exact={true} key={key}>
                                <PaymentDirectElementary paymentPage={getPaymentDirectPage(page.type)} isPromotion={page.isPromotion} />
                            </Route>
                        );
                    }

                    if (page.displayType === PaymentDirectDisplayType.ADDICTIVE) {
                        return (
                            <Route path={page.path} exact={true} key={key}>
                                <PaymentDirectAddictive paymentPage={getPaymentDirectPage(page.type)} />
                            </Route>
                        );
                    }
                    if (page.displayType === PaymentDirectDisplayType.MONTHLY_BILLING) {
                        const purchaseType = head(page.purchases);
                        if (isNil(purchaseType)) return;
                        return (
                            <Route path={page.path} exact={true} key={key}>
                                <PurchaseDirect
                                    purchaseType={purchaseType}
                                    queryParams={queryParams}
                                    token={token}
                                    direct={true}
                                    firstDate={startDateOfClass}
                                    billingDay={monthlyBillingDay}
                                    needFirstDate={true}
                                />
                            </Route>
                        );
                    }
                    if (page.displayType === PaymentDirectDisplayType.PURCHASE) {
                        const purchaseType = head(page.purchases);
                        if (isNil(purchaseType)) return;
                        return (
                            <Route path="/purchase" exact={true} key={key}>
                                <PurchaseDirect purchaseType={purchaseType} queryParams={queryParams} token={token} direct={true} />
                            </Route>
                        );
                    }

                    // displayType이 DEFAULT만 남도록 타입이 좁혀졌는 지(Type Narrowing) 검증
                    const displayType: PaymentDirectDisplayType.DEFAULT = page.displayType;

                    return (
                        <Route path={page.path} exact={true} key={key}>
                            <PaymentDirect paymentPage={getPaymentDirectPage(page.type)} />
                        </Route>
                    );
                })}
                {/*기본 결제 페이지*/}
                {pages.map((page, key) => {
                    const { type, paymentType, path, options } = page;
                    const { viewType, productCompositionType } = options;
                    const isHighGrade3ExtensionMegastudy = viewType === ViewType.GRADE_3_EXTENSION;
                    const isHighGrade3Megastudy = viewType === ViewType.GRADE_3;

                    if (productCompositionType === ProductCompositionType.PRODUCT_SET) {
                        return (
                            <Route path={path} exact={true} key={key}>
                                <PaymentSet
                                    paymentType={paymentType}
                                    token={token}
                                    direct={direct}
                                    isMobile={isMobile}
                                    queryParams={queryParams}
                                    options={options}
                                />
                            </Route>
                        );
                    }

                    if (type === PaymentPageOverallType.EXTENSION) {
                        if (isHighGrade3ExtensionMegastudy) {
                            return (
                                <Route path={path} exact={true} key={key}>
                                    <PaymentExtensionMega
                                        paymentType={paymentType}
                                        token={token}
                                        direct={direct}
                                        isMobile={isMobile}
                                        queryParams={queryParams}
                                        options={options}
                                    />
                                </Route>
                            );
                        }
                        return (
                            <Route path={path} exact={true} key={key}>
                                <PaymentExtension
                                    paymentType={paymentType}
                                    token={token}
                                    direct={direct}
                                    isMobile={isMobile}
                                    queryParams={queryParams}
                                    options={options}
                                />
                            </Route>
                        );
                    }

                    if (isHighGrade3Megastudy) {
                        return (
                            <Route path={path} exact={true} key={key}>
                                <PaymentMega
                                    paymentType={paymentType}
                                    token={token}
                                    direct={direct}
                                    isMobile={isMobile}
                                    queryParams={queryParams}
                                    options={options}
                                />
                            </Route>
                        );
                    }
                    return (
                        <Route path={path} exact={true} key={key}>
                            <Payment paymentType={paymentType} token={token} direct={direct} isMobile={isMobile} queryParams={queryParams} options={options} />
                        </Route>
                    );
                })}
                <Route>
                    <DafaultPage isLoading={pages.length === 0} />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
