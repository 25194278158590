import React, { useEffect, useState } from 'react';
import { PaymentDirectResultViewer } from '../../../components/direct/result/PaymentDirectResultComponentViewer';
import { MAX_RETRY_COUNT, PaymentResultStatus, REST_URL } from '../../../constants/constants';
import { ajax } from '../../../utils/jQuery';
import { isEmpty } from '@fxts/core';

function PaymentDirectResult(props) {
    const { isMobile, impUid, merchantUid, impSuccess, errorMsg = '', purchaseType } = props;
    const [payments, setPayments] = useState({});
    const [status, setStatus] = useState(PaymentResultStatus.PENDING);
    let retryCount = 0;

    useEffect(() => {
        const changeStatusWithPayments = (payments) => {
            if (isEmpty(payments)) {
                changeStatusPendingAndRetry();
                return;
            }
            setStatus(PaymentResultStatus.SUCCEED);
            return;
        };

        const changeStatusPendingAndRetry = () => {
            setStatus(PaymentResultStatus.PENDING);

            if (retryCount < MAX_RETRY_COUNT) {
                retryCount++;
                setTimeout(() => getPaymentInfo(), 2000);
            } else {
                setStatus(PaymentResultStatus.INVALID);
            }
        };

        const getPaymentInfo = () => {
            ajax({
                type: 'GET',
                url: REST_URL + `/payments/multiple?impUid=${impUid}`,
                success: function (result) {
                    const payments = result.data.payments;

                    const isPaymentWillUpdated = isEmpty(payments);
                    if (isPaymentWillUpdated) {
                        changeStatusPendingAndRetry();
                    } else {
                        typeof (window as any).gtag === 'function' &&
                            (window as any).gtag('config', 'UA-100968789-9', {
                                page_title: '결제 결과 페이지',
                                page_path: '/payment_result',
                            });

                        setPayments(payments);
                        changeStatusWithPayments(payments);
                    }
                },
                complete: function (result) {
                    if (result.status !== 200) changeStatusPendingAndRetry();
                },
                contentType: 'application/json',
                dataType: 'json',
            });
        };

        const init = (impSuccess) => {
            if (impUid === 'null' || merchantUid === 'null') return setStatus(PaymentResultStatus.INVALID);
            setStatus(PaymentResultStatus.PENDING);
            if (impSuccess === true) return getPaymentInfo();
            if (impSuccess === false) {
                return setStatus(PaymentResultStatus.INVALID);
            }
            setStatus(PaymentResultStatus.INVALID);
        };

        init(impSuccess);
    }, [impSuccess, impUid, merchantUid, retryCount]);

    return <PaymentDirectResultViewer payments={payments} status={status} isMobile={isMobile} purchaseType={purchaseType} errorMsg={errorMsg} />;
}

export default PaymentDirectResult;
