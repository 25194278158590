import React from 'react';
import { TopNaivgator } from '../common/TopNaivgator';
import { PurchaseModule } from './PurchaseModule';
import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    top: 66px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1;
`;

const PurchaseSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 360px;
    max-height: 720px;
    overflow-y: auto;
    background-color: #ffffff;
    padding: 1.5rem 1rem;
    border-radius: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const PurchaseModuleCard = (props) => {
    const { purchase } = props;

    return (
        <Wrapper>
            <PurchaseSectionWrapper>
                <PurchaseModule purchase={purchase} />
            </PurchaseSectionWrapper>
            <TopNaivgator />
        </Wrapper>
    );
};
