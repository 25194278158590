import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { customFetch } from '../../utils/custom-fetch';
import styled from 'styled-components';
import { setCookie } from '../../utils/cookie';

const Wrapper = styled(Paper)`
    margin-top: 64px;
    padding: 32px;
`;

const LoginForm = styled.form`
    display: grid;
    gap: 16px;
`;

export const Login = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        customFetch('POST', '/users/me/token', {
            email,
            password,
            type: 'admin',
        }).then((data) => {
            if (data.data.token) {
                props.setIsLogined(true);
                setCookie('loginToken', data.data.token, 1);
            } else {
                alert('아이디 또는 비밀번호가 틀립니다. 혹은 관리자 권한이 없습니다.');
            }
        });
    };

    return (
        <div>
            <Container maxWidth="sm">
                <Wrapper>
                    <LoginForm onSubmit={handleSubmit}>
                        <TextField
                            type="email"
                            id="email"
                            label="email"
                            color="secondary"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            variant="outlined"
                            autoComplete="email"
                        />
                        <TextField
                            autoComplete="current-password"
                            type="password"
                            id="password"
                            label="password"
                            color="secondary"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            variant="outlined"
                        />
                        <Button variant="contained" type="submit" color="secondary">
                            로그인
                        </Button>
                    </LoginForm>
                </Wrapper>
            </Container>
        </div>
    );
};
