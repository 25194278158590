import * as React from 'react';

function Advantage3(props: any) {
    return (
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_4243_16261" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="100">
                <rect y="0.5" width="100" height="100" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_4243_16261)">
                <path
                    d="M70.4241 78.6392V89.1455C70.4241 93.2121 67.1132 96.5 63.0459 96.5H12.3782C8.29849 96.5 5 93.1998 5 89.1455V17.1707C5 13.1041 8.31089 9.81619 12.3782 9.81619H21.1452M54.2789 9.81619H63.0459C67.1256 9.81619 70.4241 13.1164 70.4241 17.1707V27.677M85.8129 44.9075L67.696 62.9661C67.2868 63.374 66.6296 63.374 66.2328 62.9661L56.2133 52.9789M22.4473 30.0255L17.4003 35.0562C17.2887 35.1675 17.1027 35.1675 16.9911 35.0562L14.201 32.2751M22.4473 53.498L17.4003 58.5287C17.2887 58.64 17.1027 58.64 16.9911 58.5287L14.201 55.7476M22.4473 76.9705L17.4003 82.0012C17.2887 82.1124 17.1027 82.1124 16.9911 82.0012L14.201 79.2201M29.7758 32.5718H42.2754M29.7758 56.0566H35.914M29.7758 79.5291H47.6943M24.4809 3.5H50.9308C52.7784 3.5 54.2665 4.99561 54.2665 6.82496V12.7827C54.2665 14.6244 52.766 16.1077 50.9308 16.1077H24.4809C22.6333 16.1077 21.1452 14.612 21.1452 12.7827V6.82496C21.1452 4.98325 22.6457 3.5 24.4809 3.5ZM76.2983 28.3524C90.0423 31.5859 98.5544 45.313 95.3105 59.0128C92.0666 72.7126 78.2952 81.1973 64.5512 77.9638C50.8072 74.7304 42.2952 61.0033 45.5391 47.3035C48.7829 33.6037 62.5543 25.119 76.2983 28.3524Z"
                    stroke="currentColor"
                    stroke-width="3.7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                />
            </g>
        </svg>
    );
}

export default Advantage3;
