import React from 'react';

export const DafaultPage = (props) => {
    const { isLoading = true, children } = props;
    if (isLoading) {
        return <></>;
    }
    return (
        <div style={{ textAlign: 'center' }}>
            <h1>존재하지 않는 페이지입니다.</h1>
            <div>{children}</div>
        </div>
    );
};
