import React from 'react';
import { PRODUCT_RULES, Rule, RuleImage } from '../../constants/product-rules';

const ProductRules = (props) => {
    const { isMobile } = props;

    return (
        <div style={{ display: 'flex', width: '100%', padding: '32px', backgroundColor: '#F2F2F2', justifyContent: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: isMobile ? '12px' : '16px', gap: '36px', margin: isMobile ? '' : 'auto 15%' }}>
                <Rules />
            </div>
        </div>
    );
};

const Rules = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
            {PRODUCT_RULES.map((rule, ruleKey) => {
                return <ProductRule rule={rule} ruleKey={ruleKey} showTitle={true} />;
            })}
        </div>
    );
};

export const ProductRule = (props: { rule: Rule; ruleKey: number; showTitle?: boolean }) => {
    const { rule, ruleKey, showTitle } = props;
    return (
        <div>
            <div style={{ marginBottom: '8px' }}>
                {showTitle && <Title>{rule.text}</Title>}
                <div>{rule.description}</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {rule.rules?.map((subRule, subKey) => {
                    return (
                        <div key={subKey}>
                            <SubTitle>
                                {ruleKey + 1}-{subKey + 1}. {subRule.text}
                            </SubTitle>
                            <div>{subRule.description}</div>
                            <RuleList rules={subRule.rules} depth={1} />
                        </div>
                    );
                })}
                {rule.images?.map((image, key) => {
                    return (
                        <div key={key}>
                            <div>{image.text}</div>
                            <img src={image.src} style={{ width: '100%' }} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const Title = (props) => {
    return <h2 style={{ fontWeight: 'bold', fontSize: '18px', margin: 0 }}>{props.children}</h2>;
};

const SubTitle = (props) => {
    return <h3 style={{ fontWeight: 'bold', fontSize: '16px', margin: 0 }}>{props.children}</h3>;
};

const RuleList = (props: { rules?: Rule[]; images?: RuleImage[]; depth: number }) => {
    const { rules, images, depth } = props;
    const listStyleType = depth % 3 === 1 ? 'decimal' : depth % 3 === 2 ? 'lower-alpha' : 'lower-roman';

    return (
        <ol
            style={{
                listStyleType,
                marginBlockStart: '0em',
                marginBlockEnd: '0em',
                paddingInlineStart: '1.5rem',
            }}
        >
            {rules?.map((rule, key) => {
                return (
                    <li key={key}>
                        <p style={{ margin: '4px 0px' }}>{rule.text}</p>
                        <p style={{ margin: '4px 0px' }}>{rule.description}</p>
                        <RuleList rules={rule.rules} images={rule.images} depth={depth + 1} />
                    </li>
                );
            })}
            {images?.map((image, key) => {
                return (
                    <div key={key}>
                        <div>{image.text}</div>
                        <img src={image.src} style={{ width: '90%', maxWidth: '900px' }} />
                    </div>
                );
            })}
        </ol>
    );
};

export default ProductRules;
